import { render, staticRenderFns } from "./default.vue?vue&type=template&id=5cb2a4cc&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthenticationAlert: require('E:/works/企业服务www/new-B-www-frontend/components/authenticationAlert.vue').default,ReapplyAlert: require('E:/works/企业服务www/new-B-www-frontend/components/reapplyAlert.vue').default,LoginAlert: require('E:/works/企业服务www/new-B-www-frontend/components/login/loginAlert.vue').default,GoBackTop: require('E:/works/企业服务www/new-B-www-frontend/components/goBackTop.vue').default})
