import { getToken} from '@/utils/auth';

export const loginStatusMixin = {
  data(){
    return{
      isLogin: false, //登陆状态
    }
  },
  mounted() {
    this.isLogin = getToken();
  },
}
