export const routerMixin = {
  data() {
    return {
      menuList: [
        {
          label: '工具软件', key: 'smpro', value: 'smpro', itemListShow: true,
          children: [
            {
              desc: '固网宽带和移动网络的专业测试工具，全方位检测网络性能，了解真实网络体验，快速定位网络问题',
              label: '网维助手',
              path: '/smpro',
              statistics: 'smpro',
              parent: 'smpro',
              img: require('@/assets/imgs/header/wwzs.png')
            },
            {
              label: '网优宝',
              path: '/apps',
              statistics: 'wyb',
              type: 'wyb',
              desc: '专业装维网优，路测、室内测试工具',
              img: require('@/assets/imgs/header/wyb.png'),
              parent: 'smpro',
            },
            {
              label: '万能测速',
              path: '/apps',
              statistics: 'wncs',
              type: 'wncs',
              desc: '内外网环境，网络速度与质量测试工具',
              img: require('@/assets/imgs/header/wncs.png'),
              parent: 'smpro',
            },
            {
              label: 'Wi-Fi走测',
              path: '/apps',
              statistics: 'wfzc',
              type: 'wfzc',
              desc: 'Wi-Fi信号强度边走边测，基于移动路径生成强度覆盖图',
              img: require('@/assets/imgs/header/wfzc.png'),
              parent: 'smpro',
            },
            {
              label: 'Wi-Fi全屋通',
              path: '/apps',
              statistics: 'wfqwt',
              type: 'wfqwt',
              desc: '全屋Wi-Fi信号强度覆盖、用户体验测试工具',
              img: require('@/assets/imgs/header/wfqwt.svg'),
              parent: 'smpro',
            },
            {
              label: 'Wi-Fi助手',
              path: '/apps',
              statistics: 'wfzs',
              type: 'wfzs',
              desc: 'Wi-Fi设备参数、干扰等状态测试工具',
              img: require('@/assets/imgs/header/wfzs.png'),
              parent: 'smpro',
            },
            {
              label: '抓包帮手',
              path: '/apps',
              statistics: 'zbbs',
              type: 'zbbs',
              desc: '移动端抓包测试工具',
              img: require('@/assets/imgs/apps/zbbs-icon.svg'),
              parent: 'smpro',
            },

          ]
        },
        {
          label: 'SDK', key: 'sdk', value: 'SDK', itemListShow: true,
          children: [
            {
              label: '网络速度与质量',
              value: '网络速度与质量',
              children: [
                {
                  label: '网络测速SDK',
                  path: '/speedtest-sdk',
                  parent: 'sdk',
                  statistics: 'speedtestsdk',
                  value: '网络测速SDK',
                  special: 'hot',
                  desc: '测速网自研测速算法，多系统可用，App、PC应用、路由器等产品均可集成',
                  img: require('@/assets/imgs/header/sdks/wlcs-sdk.png')
                },
                {
                  label: '网络测速插件',
                  path: '/plugin',
                  parent: 'sdk',
                  statistics: 'plugin',
                  value: '网络测速插件',
                  special: '',
                  desc: "多用于浏览器测速场景，WEB、移动端H5、小程序内嵌H5均可集成",
                  img: require('@/assets/imgs/header/sdks/wlcs-plugin.png')
                },
                {
                  label: '命令行测速CLI',
                  path: '/cli',
                  parent: 'sdk',
                  statistics: 'cli',
                  value: '命令行测速CLI',
                  special: '',
                  desc: '网络测速命令行，多用于PC设备',
                  img: require('@/assets/imgs/header/sdks/mlh-cli.png')
                },
                {
                  label: '实时网速监测SDK',
                  path: '/realtime-sdk',
                  parent: 'sdk',
                  statistics: 'realtimesdk',
                  value: '实时网络监测SDK',
                  special: 'new',
                  desc: '设备实时上下行网络速度监测，多用于PC设备',
                  img: require('@/assets/imgs/header/sdks/realtime-sdk.png')
                }
              ]
            },
            {
              label: '用户感知测试',
              value: '用户感知测试',
              children: [
                {
                  label: '网页打开速度SDK',
                  path: '/web-open-speed-sdk',
                  parent: 'sdk',
                  statistics: 'webopenspeedsdk',
                  value: '网页打开速度SDK',
                  special: 'new',
                  desc: '主流网站打开速度测试，适用于App、路由器等产品集成使用',
                  img: require('@/assets/imgs/header/sdks/web-open-speed-sdk.png')
                },
                {
                  label: '游戏测速SDK',
                  path: '/game-sdk',
                  parent: 'sdk',
                  statistics: 'gamesdk',
                  value: '游戏测速SDK',
                  special: '',
                  desc: '主流游戏平台时延测试，适用于App、路由器等产品集成使用',
                  img: require('@/assets/imgs/header/sdks/game-sdk.png')
                },
                {
                  label: '综合测速SDK',
                  path: '/combo-sdk',
                  parent: 'sdk',
                  statistics: 'combosdk',
                  value: '综合测速SDK',
                  special: '',
                  desc: '资讯、电商、视频等场景主流平台时延测试，适用于App、路由器等产品集成使用',
                  img: require('@/assets/imgs/header/sdks/combo-sdk.png')
                },
                {
                  label: '视频测试SDK',
                  path: '/video-sdk',
                  parent: 'sdk',
                  statistics: 'videosdk',
                  value: '视频测试SDK',
                  special: '',
                  desc: '视频播放质量测试工具，适用于安卓、iOS系统软件产品集成',
                  img: require('@/assets/imgs/header/sdks/video-sdk.png')
                },
              ]
            },
            {
              label: '场景化网络测试',
              value: '场景化网络测试',
              children: [
                {
                  label: '全屋Wi-Fi检测SDK',
                  path: '/whole-home-wifi-sdk',
                  parent: 'sdk',
                  statistics: 'whole-home-wifi',
                  value: '全屋Wi-Fi检测SDK',
                  special: '',
                  desc: '全屋Wi-Fi信号强度覆盖、上网体验诊断测试，适用于安卓系统软件产品集成',
                  img: require('@/assets/imgs/header/sdks/whole-home-wifi-sdk.png')
                },
                {
                  label: '异常诊断SDK',
                  path: '/appmalf-sdk',
                  parent: 'sdk',
                  statistics: 'appmalfsdk',
                  value: '异常诊断SDK',
                  special: '',
                  desc: '定位网络问题来源，适用于安卓、iOS系统软件产品集成',
                  img: require('@/assets/imgs/header/sdks/appmalf-sdk.png')
                },
              ]
            },
            {
              label: '专项测试',
              value: '专项测试',
              children: [
                {
                  label: 'Wi-Fi分析SDK',
                  path: '/wifi-analysis-sdk',
                  parent: 'sdk',
                  statistics: 'wifianalysissdk',
                  value: 'Wi-Fi分析SDK',
                  special: 'new',
                  desc: 'Wi-Fi设备参数、干扰、在线终端等状态分析，适用于安卓系统软件产品集成',
                  img: require('@/assets/imgs/header/sdks/wifi-analysis-sdk.png')
                },
                {
                  label: '移动网络检测SDK',
                  path: '/mobile-network-sdk',
                  parent: 'sdk',
                  statistics: 'mobilenetworksdk',
                  value: '移动网络检测SDK',
                  special: 'new',
                  desc: '移动4G、5G等网络，信号强度、邻区等状态分析，适用于安卓系统软件产品集成',
                  img: require('@/assets/imgs/header/sdks/mobile-network-sdk.png')
                }
              ]
            }
          ]
        },
        {
          label: '解决方案', key: 'slove', value: '解决方案', itemListShow: true,
          children: [
            {
              label: '网络质量监测系统',
              path: '/quality-monitoring',
              parent: 'slove',
              statistics: 'speedtest-monitoring',
              value: '网络质量监测系统',
              special: '',
              desc: '互联网、物联网设备网络质量监测，后台远程下发拨测任务',
              img: require('@/assets/imgs/header/slove/quality-monitoring.png')
            },
            {
              label: '用户满意度提升',
              path: '/satisfaction-improvement',
              parent: 'slove',
              statistics: 'increased-satisfaction',
              value: '用户满意度提升',
              special: 'new',
              desc: '精准洞察网内用户上网体验，提前解决问题，提升用户满意度',
              img: require('@/assets/imgs/header/slove/increased-satisfaction.png')
            },
            {
              label: '测速系统私有化部署',
              path: '/speedtest-deployment',
              parent: 'slove',
              statistics: 'privatization-index',
              value: '测速系统私有化部署',
              special: 'new',
              desc: '基于测速网自研网络速度和网络体验测试系统、客户端工具等产品，为您提供私有化部署定制服务',
              img: require('@/assets/imgs/header/slove/privatization.png')
            },
            {
              label: '大带宽检测',
              path: '/high-bandwidth',
              parent: 'slove',
              statistics: 'high-bandwidth',
              value: '大带宽检测',
              special: '',
              desc: '千兆、万兆以上带宽检测服务',
              img: require('@/assets/imgs/header/slove/high-bandwidth.png')
            },
            {
              label: '内网测速',
              path: '/intranet',
              parent: 'slove',
              statistics: 'intranet',
              value: '内网测速',
              special: '',
              desc: '精准分析内网中各网络环节、各区域的网速及网络质量情况，定位网络问题，提升用网体验',
              img: require('@/assets/imgs/header/slove/intranet.png')
            },
            {
              label: '网络设备测速',
              path: '/network-equipment',
              parent: 'slove',
              statistics: 'speedtest-networkequipment',
              value: '网络设备测速',
              special: 'new',
              desc: '单/多网卡设备测速服务，可形成分段测速系统',
              img: require('@/assets/imgs/header/slove/network-equipment.png')
            },
            {
              label: '添加测速点',
              path: '/nodes',
              parent: 'slove',
              statistics: 'add-nodes',
              value: '添加测速点',
              special: '',
              desc: '为当地/网内用户提升测速体验',
              img: require('@/assets/imgs/header/slove/nodes.png')
            },
            {
              label: '定制开发',
              path: '/custom',
              parent: 'slove',
              statistics: 'speedtest-custom',
              value: '定制开发',
              special: '',
              desc: '网络质量及用户体验检测领域多年服务经验，方案、客户端等产品均支持定制',
              img: require('@/assets/imgs/header/slove/custom.png')
            },
            {
              label: '委托测试',
              path: '/testing-services',
              parent: 'slove',
              statistics: 'speedtest-testing-services',
              value: '委托测试',
              special: '',
              desc: '基于测速网服务经验，提供商用/家用网络设备测试服务',
              img: require('@/assets/imgs/header/slove/testing-services.svg')
            },
            {
              label: '边缘安全加速',
              path: '/edge-security-acceleration',
              parent: 'slove',
              statistics: '/edge-security-acceleration',
              value: '边缘安全加速',
              special: '',
              desc: '边缘安全加速平台依托全国各地的分布式边缘资源，实现网络底层对性能、安全、算力原子能力编排融入统一网络，实现多终端、多协议（5G/L3/L4/L7等）all-in-one的网络统一接入，满足不同场景需求的性能及安全智能边缘网络',
              img: require('@/assets/imgs/header/slove/edge-security-acceleration.svg')
            },
          ]
        },
        {label: '大数据', path: '/data', statistics: 'speedtest-data', value: '大数据'},
        {label: '业务咨询', path: '/consulting', statistics: 'consulting', value: '业务咨询'},
      ]
    }
  }
}
