
import {mapActions, mapState} from "vuex";

export default {
  props: ['dataList', 'subpathname'],
  computed: {
    ...mapState(['smproApp'])
  },
  methods: {
    ...mapActions(['setSmproApp']),
    goToLinkFn(link, val, indexChild) {
      this.$parent.menuHide = true;
      if (val) {
        this.setSmproApp(indexChild);
      }
      if (!this.subpathname.includes(link)) {
        this.$router.push({path: link});
      }
      setTimeout(() => {
        this.$parent.menuHide = false;
      }, 100)
    },

    /**
     * 激活类名的处理
     * */
    activeFilter(itemChild, indexChild) {
      if (!this.subpathname.includes(itemChild.path)) {
        return false;
      }
      return itemChild.type ? indexChild === this.smproApp : this.subpathname.includes(itemChild.path)
    }
  },
  mounted() {
    this.$parent.pathname = this.dataList.key
  },
}
