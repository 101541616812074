export const Authentication = () => import('../..\\components\\authentication.vue' /* webpackChunkName: "components/authentication" */).then(c => wrapFunctional(c.default || c))
export const AuthenticationAlert = () => import('../..\\components\\authenticationAlert.vue' /* webpackChunkName: "components/authentication-alert" */).then(c => wrapFunctional(c.default || c))
export const AuthenticationSuccess = () => import('../..\\components\\authenticationSuccess.vue' /* webpackChunkName: "components/authentication-success" */).then(c => wrapFunctional(c.default || c))
export const DescribeTable = () => import('../..\\components\\describeTable.vue' /* webpackChunkName: "components/describe-table" */).then(c => wrapFunctional(c.default || c))
export const Download = () => import('../..\\components\\download.vue' /* webpackChunkName: "components/download" */).then(c => wrapFunctional(c.default || c))
export const FlowStep = () => import('../..\\components\\flow-step.vue' /* webpackChunkName: "components/flow-step" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../..\\components\\footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const GoBackTop = () => import('../..\\components\\goBackTop.vue' /* webpackChunkName: "components/go-back-top" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../..\\components\\header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Introduce = () => import('../..\\components\\introduce.vue' /* webpackChunkName: "components/introduce" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../..\\components\\loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const NetworkeList = () => import('../..\\components\\networke-list.vue' /* webpackChunkName: "components/networke-list" */).then(c => wrapFunctional(c.default || c))
export const ReapplyAlert = () => import('../..\\components\\reapplyAlert.vue' /* webpackChunkName: "components/reapply-alert" */).then(c => wrapFunctional(c.default || c))
export const Report = () => import('../..\\components\\report.vue' /* webpackChunkName: "components/report" */).then(c => wrapFunctional(c.default || c))
export const ReportV2 = () => import('../..\\components\\reportV2.vue' /* webpackChunkName: "components/report-v2" */).then(c => wrapFunctional(c.default || c))
export const SupportSystemList = () => import('../..\\components\\support-system-list.vue' /* webpackChunkName: "components/support-system-list" */).then(c => wrapFunctional(c.default || c))
export const AccountManagementAddEmail = () => import('../..\\components\\account-management\\addEmail.vue' /* webpackChunkName: "components/account-management-add-email" */).then(c => wrapFunctional(c.default || c))
export const AccountManagementChangeEmail = () => import('../..\\components\\account-management\\changeEmail.vue' /* webpackChunkName: "components/account-management-change-email" */).then(c => wrapFunctional(c.default || c))
export const AccountManagementChangePass = () => import('../..\\components\\account-management\\changePass.vue' /* webpackChunkName: "components/account-management-change-pass" */).then(c => wrapFunctional(c.default || c))
export const AccountManagementChangePhone = () => import('../..\\components\\account-management\\changePhone.vue' /* webpackChunkName: "components/account-management-change-phone" */).then(c => wrapFunctional(c.default || c))
export const AccountManagementChangeUserName = () => import('../..\\components\\account-management\\changeUserName.vue' /* webpackChunkName: "components/account-management-change-user-name" */).then(c => wrapFunctional(c.default || c))
export const HeaderResolvePop = () => import('../..\\components\\header\\resolvePop.vue' /* webpackChunkName: "components/header-resolve-pop" */).then(c => wrapFunctional(c.default || c))
export const HeaderSdksPop = () => import('../..\\components\\header\\sdksPop.vue' /* webpackChunkName: "components/header-sdks-pop" */).then(c => wrapFunctional(c.default || c))
export const HeaderToolSoftwarePop = () => import('../..\\components\\header\\toolSoftwarePop.vue' /* webpackChunkName: "components/header-tool-software-pop" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderNavListTitle = () => import('../..\\components\\layoutHeader\\navListTitle.vue' /* webpackChunkName: "components/layout-header-nav-list-title" */).then(c => wrapFunctional(c.default || c))
export const LoginAccountLogin = () => import('../..\\components\\login\\accountLogin.vue' /* webpackChunkName: "components/login-account-login" */).then(c => wrapFunctional(c.default || c))
export const LoginCodeLogin = () => import('../..\\components\\login\\codeLogin.vue' /* webpackChunkName: "components/login-code-login" */).then(c => wrapFunctional(c.default || c))
export const LoginInquiryAlert = () => import('../..\\components\\login\\inquiryAlert.vue' /* webpackChunkName: "components/login-inquiry-alert" */).then(c => wrapFunctional(c.default || c))
export const LoginAlert = () => import('../..\\components\\login\\loginAlert.vue' /* webpackChunkName: "components/login-alert" */).then(c => wrapFunctional(c.default || c))
export const LoginNotRegister = () => import('../..\\components\\login\\notRegister.vue' /* webpackChunkName: "components/login-not-register" */).then(c => wrapFunctional(c.default || c))
export const LoginRegisterAccount = () => import('../..\\components\\login\\registerAccount.vue' /* webpackChunkName: "components/login-register-account" */).then(c => wrapFunctional(c.default || c))
export const MyappsDataDetails = () => import('../..\\components\\myapps\\dataDetails.vue' /* webpackChunkName: "components/myapps-data-details" */).then(c => wrapFunctional(c.default || c))
export const MyappsDetailedModal = () => import('../..\\components\\myapps\\detailedModal.vue' /* webpackChunkName: "components/myapps-detailed-modal" */).then(c => wrapFunctional(c.default || c))
export const MyappsTreeNav = () => import('../..\\components\\myapps\\treeNav.vue' /* webpackChunkName: "components/myapps-tree-nav" */).then(c => wrapFunctional(c.default || c))
export const PrivatizationBasic = () => import('../..\\components\\privatization\\PrivatizationBasic.vue' /* webpackChunkName: "components/privatization-basic" */).then(c => wrapFunctional(c.default || c))
export const PrivatizationClassify = () => import('../..\\components\\privatization\\PrivatizationClassify.vue' /* webpackChunkName: "components/privatization-classify" */).then(c => wrapFunctional(c.default || c))
export const PrivatizationSpecialty = () => import('../..\\components\\privatization\\PrivatizationSpecialty.vue' /* webpackChunkName: "components/privatization-specialty" */).then(c => wrapFunctional(c.default || c))
export const PrivatizationStandard = () => import('../..\\components\\privatization\\PrivatizationStandard.vue' /* webpackChunkName: "components/privatization-standard" */).then(c => wrapFunctional(c.default || c))
export const RecordDataAlert = () => import('../..\\components\\record\\dataAlert.vue' /* webpackChunkName: "components/record-data-alert" */).then(c => wrapFunctional(c.default || c))
export const MyappsCliDocmixin = () => import('../..\\components\\myapps\\cli\\docmixin.js' /* webpackChunkName: "components/myapps-cli-docmixin" */).then(c => wrapFunctional(c.default || c))
export const MyappsHighBandwidthDocmixin = () => import('../..\\components\\myapps\\high-bandwidth\\docmixin.js' /* webpackChunkName: "components/myapps-high-bandwidth-docmixin" */).then(c => wrapFunctional(c.default || c))
export const MyappsIntranetDocmixin = () => import('../..\\components\\myapps\\intranet\\docmixin.js' /* webpackChunkName: "components/myapps-intranet-docmixin" */).then(c => wrapFunctional(c.default || c))
export const MyappsIntranetLanBase = () => import('../..\\components\\myapps\\intranet\\lan-base.vue' /* webpackChunkName: "components/myapps-intranet-lan-base" */).then(c => wrapFunctional(c.default || c))
export const MyappsIntranetLanPro = () => import('../..\\components\\myapps\\intranet\\lan-pro.vue' /* webpackChunkName: "components/myapps-intranet-lan-pro" */).then(c => wrapFunctional(c.default || c))
export const SmproBusiness = () => import('../..\\components\\smpro\\business.vue' /* webpackChunkName: "components/smpro-business" */).then(c => wrapFunctional(c.default || c))
export const SmproMobelnetworktest = () => import('../..\\components\\smpro\\mobelnetworktest.vue' /* webpackChunkName: "components/smpro-mobelnetworktest" */).then(c => wrapFunctional(c.default || c))
export const SmproSpeedtest = () => import('../..\\components\\smpro\\speedtest.vue' /* webpackChunkName: "components/smpro-speedtest" */).then(c => wrapFunctional(c.default || c))
export const SmproWifitest = () => import('../..\\components\\smpro\\wifitest.vue' /* webpackChunkName: "components/smpro-wifitest" */).then(c => wrapFunctional(c.default || c))
export const MyappsPlugin = () => import('../..\\components\\myapps\\plugin\\index.vue' /* webpackChunkName: "components/myapps-plugin" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkDocModule = () => import('../..\\components\\myapps\\sdk\\sdkDocModule.vue' /* webpackChunkName: "components/myapps-sdk-doc-module" */).then(c => wrapFunctional(c.default || c))
export const SpeedtestAdsSwiper = () => import('../..\\components\\speedtest\\adsSwiper.vue' /* webpackChunkName: "components/speedtest-ads-swiper" */).then(c => wrapFunctional(c.default || c))
export const SpeedtestSdkLvTable = () => import('../..\\components\\speedtest-sdk\\lvTable.vue' /* webpackChunkName: "components/speedtest-sdk-lv-table" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkAppmalfsdkAndroid = () => import('../..\\components\\myapps\\sdk\\appmalfsdk\\android.vue' /* webpackChunkName: "components/myapps-sdk-appmalfsdk-android" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkAppmalfsdkDocmixin = () => import('../..\\components\\myapps\\sdk\\appmalfsdk\\docmixin.js' /* webpackChunkName: "components/myapps-sdk-appmalfsdk-docmixin" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkAppmalfsdkIos = () => import('../..\\components\\myapps\\sdk\\appmalfsdk\\ios.vue' /* webpackChunkName: "components/myapps-sdk-appmalfsdk-ios" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkComboAndroid = () => import('../..\\components\\myapps\\sdk\\combo\\android.vue' /* webpackChunkName: "components/myapps-sdk-combo-android" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkComboDocmixin = () => import('../..\\components\\myapps\\sdk\\combo\\docmixin.js' /* webpackChunkName: "components/myapps-sdk-combo-docmixin" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkComboIos = () => import('../..\\components\\myapps\\sdk\\combo\\ios.vue' /* webpackChunkName: "components/myapps-sdk-combo-ios" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkGameAndroid = () => import('../..\\components\\myapps\\sdk\\game\\android.vue' /* webpackChunkName: "components/myapps-sdk-game-android" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkGameDocmixin = () => import('../..\\components\\myapps\\sdk\\game\\docmixin.js' /* webpackChunkName: "components/myapps-sdk-game-docmixin" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkGameIos = () => import('../..\\components\\myapps\\sdk\\game\\ios.vue' /* webpackChunkName: "components/myapps-sdk-game-ios" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkRealTimeSdkAndroid = () => import('../..\\components\\myapps\\sdk\\realTimeSdk\\android.vue' /* webpackChunkName: "components/myapps-sdk-real-time-sdk-android" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkRealTimeSdkDocmixin = () => import('../..\\components\\myapps\\sdk\\realTimeSdk\\docmixin.js' /* webpackChunkName: "components/myapps-sdk-real-time-sdk-docmixin" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkRealTimeSdkMacos = () => import('../..\\components\\myapps\\sdk\\realTimeSdk\\macos.vue' /* webpackChunkName: "components/myapps-sdk-real-time-sdk-macos" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkSpeedtestAndroid = () => import('../..\\components\\myapps\\sdk\\speedtest\\android.vue' /* webpackChunkName: "components/myapps-sdk-speedtest-android" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkSpeedtestDocmixin = () => import('../..\\components\\myapps\\sdk\\speedtest\\docmixin.js' /* webpackChunkName: "components/myapps-sdk-speedtest-docmixin" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkSpeedtestIos = () => import('../..\\components\\myapps\\sdk\\speedtest\\ios.vue' /* webpackChunkName: "components/myapps-sdk-speedtest-ios" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkSpeedtestLinux = () => import('../..\\components\\myapps\\sdk\\speedtest\\linux.vue' /* webpackChunkName: "components/myapps-sdk-speedtest-linux" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkSpeedtestMacos = () => import('../..\\components\\myapps\\sdk\\speedtest\\macos.vue' /* webpackChunkName: "components/myapps-sdk-speedtest-macos" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkSpeedtestWeb = () => import('../..\\components\\myapps\\sdk\\speedtest\\web.vue' /* webpackChunkName: "components/myapps-sdk-speedtest-web" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkSpeedtestWindows = () => import('../..\\components\\myapps\\sdk\\speedtest\\windows.vue' /* webpackChunkName: "components/myapps-sdk-speedtest-windows" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkVideoAndroid = () => import('../..\\components\\myapps\\sdk\\video\\android.vue' /* webpackChunkName: "components/myapps-sdk-video-android" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkVideoDocmixin = () => import('../..\\components\\myapps\\sdk\\video\\docmixin.js' /* webpackChunkName: "components/myapps-sdk-video-docmixin" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkVideoIos = () => import('../..\\components\\myapps\\sdk\\video\\ios.vue' /* webpackChunkName: "components/myapps-sdk-video-ios" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkWeChatDocmixin = () => import('../..\\components\\myapps\\sdk\\WeChat\\docmixin.js' /* webpackChunkName: "components/myapps-sdk-we-chat-docmixin" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkWeChatWx = () => import('../..\\components\\myapps\\sdk\\WeChat\\wx.vue' /* webpackChunkName: "components/myapps-sdk-we-chat-wx" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkWebOpenTimeAndroid = () => import('../..\\components\\myapps\\sdk\\webOpenTime\\android.vue' /* webpackChunkName: "components/myapps-sdk-web-open-time-android" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkWebOpenTimeDocmixin = () => import('../..\\components\\myapps\\sdk\\webOpenTime\\docmixin.js' /* webpackChunkName: "components/myapps-sdk-web-open-time-docmixin" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkWebOpenTimeIos = () => import('../..\\components\\myapps\\sdk\\webOpenTime\\ios.vue' /* webpackChunkName: "components/myapps-sdk-web-open-time-ios" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkWholeHouseWifiAndroid = () => import('../..\\components\\myapps\\sdk\\wholeHouseWifi\\android.vue' /* webpackChunkName: "components/myapps-sdk-whole-house-wifi-android" */).then(c => wrapFunctional(c.default || c))
export const MyappsSdkWholeHouseWifiDocmixin = () => import('../..\\components\\myapps\\sdk\\wholeHouseWifi\\docmixin.js' /* webpackChunkName: "components/myapps-sdk-whole-house-wifi-docmixin" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
