import request from '@/utils/request';
import { STATISTICS_URL } from './api-list';

// 埋点
export function statistics(params) {
  return request({
    url: STATISTICS_URL,
    method: 'get',
    params: params
  });
}
