
export default {
  name: "sdksPop",
  props: ['list','subpathname'],
  data() {
    return {
      activeNum: 0
    }
  },
  methods: {
    changeSdk(index) {
      this.activeNum = index
    },
    goToPage(link) {
      this.$parent.menuHide = true;
      if (!this.subpathname.includes(link)) {
        this.$router.push({path: link});
      }
      setTimeout(() => {
        this.$parent.menuHide = false;
      }, 100)
    }
  }
}
