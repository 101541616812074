import { setToken, removeToken } from '@/utils/auth';

export const logoutMixin = {
  methods:{
    // 退出登录
    logout(){
      removeToken();
      setToken('');
      if(location.pathname === '/information' ||
      location.pathname.indexOf('/products') > -1 ||
      location.pathname.indexOf('/authentication') > -1 ||
      location.pathname.indexOf('/settings') > -1 ||
      location.pathname.indexOf('/management') > -1
      ){
        location.href = '/';
      } else{
        location.href = location.href;
      }
    }
  }
}
