import Cookies from 'js-cookie';

export function getToken() {
  // return localStorage.getItem('B-Token')
  return Cookies.get('B-Token');
}

export function setToken(token) {
  // return localStorage.setItem('B-Token',token)
  return Cookies.set('B-Token', token);
}

export function removeToken() {
  // return localStorage.removeItem('B-Token')
  return Cookies.remove('B-Token');
}

