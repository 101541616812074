export default ({app, store}) => {
  app.router.beforeEach((to, from, next) => {
    let headTitleMap = new Map([
      ['index', '企业服务'],
      ['plugin', '网络测速插件'],
      ['appmalf-sdk', '异常诊断SDK'],
      ['authentication', '管理中心'],
      ['cli', '命令行测速CLI'],
      ['testing-services', '委托测试'],
      ['combo-sdk', '综合测速SDK'],
      ['custom', '定制开发'],
      ['data', '数据服务'],
      ['forget-password', '忘记密码'],
      ['game-sdk', '游戏测速SDK'],
      ['high-bandwidth', '大带宽检测'],
      ['speedtest-deployment', '测速系统私有化部署'],
      ['information', '企业信息'],
      ['intranet', '内网测速'],
      ['network-equipment', '网络设备测速'],
      ['products', '我的产品'],
      ['products-speedtest-package', '测速包'],
      ['products-cli', 'CLI列表'],
      ['products-cli-data', 'CLI测速数据'],
      ['products-cli-data-download', 'CLI数据任务中心'],
      ['products-cli-document', 'CLI集成文档'],
      ['products-high-bandwidth', '大带宽检测列表'],
      ['products-high-bandwidth-data', '大带宽检测测速数据'],
      ['products-high-bandwidth-data-download', '大带宽数据任务中心'],
      ['products-high-bandwidth-document', '大带宽检测集成文档'],
      ['products-intranet', '内网测速列表'],
      ['products-intranet-document', '内网测速集成文档'],
      ['products-plugin', '插件列表'],
      ['products-plugin-data', '插件测速数据'],
      ['products-plugin-data-download', '插件数据任务中心'],
      ['products-sdk', 'SDK列表'],
      ['products-sdk-data', 'SDK测速数据'],
      ['products-sdk-data-download', 'SDK数据任务中心'],
      ['products-sdk-document', 'SDK集成文档'],
      ['quality-monitoring', '网络质量监测系统'],
      ['settings', '账号管理'],
      ['settings-close', '账号注销'],
      ['speedtest-sdk', '网络测速SDK'],
      ['video-sdk', '视频测试SDK'],
      ['operators', '运营商解决方案'],
      ['whole-home-wifi-sdk', '全屋Wi-Fi检测SDK'],
      ['nodes', '添加测速点'],
      ['smpro', '网维助手'],
      ['web-open-speed-sdk', '网页打开速度SDK'],
      ['wifi-analysis-sdk', 'Wi-Fi分析SDK'],
      ['mobile-network-sdk', '移动网络检测SDK'],
      ['apps', '工具软件'],
      ['satisfaction-improvement', '用户满意度提升'],
      ['management', '管理中心'],
      ['consulting', '业务咨询'],
      ['speedtest', '政企专线测试'],
      ['realtime-sdk', '实时网速监测SDK'],
      ['edge-security-acceleration', '边缘安全加速'],
    ])
    let resHeadTitle = headTitleMap.get(to.name) || '企业服务';
    document.title = `${resHeadTitle} - 专业测网速, 网速测试, 宽带提速, 游戏测速, 直播测速, 5G测速, 物联网监测, Wi-Fi 7,Wi-Fi 6,FTTR,全屋Wi-Fi  - SpeedTest.cn`;
    next();
  })
}
