import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7e11349f = () => interopDefault(import('..\\pages\\appmalf-sdk\\index.vue' /* webpackChunkName: "pages/appmalf-sdk/index" */))
const _565c2bf2 = () => interopDefault(import('..\\pages\\apps\\index.vue' /* webpackChunkName: "pages/apps/index" */))
const _5af53b81 = () => interopDefault(import('..\\pages\\authentication\\index.vue' /* webpackChunkName: "pages/authentication/index" */))
const _2bbb19ca = () => interopDefault(import('..\\pages\\cli\\index.vue' /* webpackChunkName: "pages/cli/index" */))
const _ab3cddc0 = () => interopDefault(import('..\\pages\\combo-sdk\\index.vue' /* webpackChunkName: "pages/combo-sdk/index" */))
const _7ad31e44 = () => interopDefault(import('..\\pages\\commitment\\index.vue' /* webpackChunkName: "pages/commitment/index" */))
const _10baf97e = () => interopDefault(import('..\\pages\\compliance\\index.vue' /* webpackChunkName: "pages/compliance/index" */))
const _37c1ee43 = () => interopDefault(import('..\\pages\\consulting\\index.vue' /* webpackChunkName: "pages/consulting/index" */))
const _2275ebe8 = () => interopDefault(import('..\\pages\\custom\\index.vue' /* webpackChunkName: "pages/custom/index" */))
const _54185c62 = () => interopDefault(import('..\\pages\\data\\index.vue' /* webpackChunkName: "pages/data/index" */))
const _672e1708 = () => interopDefault(import('..\\pages\\edge-security-acceleration\\index.vue' /* webpackChunkName: "pages/edge-security-acceleration/index" */))
const _1bb41540 = () => interopDefault(import('..\\pages\\forget-password\\index.vue' /* webpackChunkName: "pages/forget-password/index" */))
const _2fdd4afa = () => interopDefault(import('..\\pages\\game-sdk\\index.vue' /* webpackChunkName: "pages/game-sdk/index" */))
const _41adf2f3 = () => interopDefault(import('..\\pages\\high-bandwidth\\index.vue' /* webpackChunkName: "pages/high-bandwidth/index" */))
const _e31242e2 = () => interopDefault(import('..\\pages\\information\\index.vue' /* webpackChunkName: "pages/information/index" */))
const _52c788d8 = () => interopDefault(import('..\\pages\\inquiry\\index.vue' /* webpackChunkName: "pages/inquiry/index" */))
const _7aa65c5a = () => interopDefault(import('..\\pages\\intranet\\index.vue' /* webpackChunkName: "pages/intranet/index" */))
const _0be70856 = () => interopDefault(import('..\\pages\\management\\index.vue' /* webpackChunkName: "pages/management/index" */))
const _12323009 = () => interopDefault(import('..\\pages\\mobile-network-sdk\\index.vue' /* webpackChunkName: "pages/mobile-network-sdk/index" */))
const _6a01db28 = () => interopDefault(import('..\\pages\\network-equipment\\index.vue' /* webpackChunkName: "pages/network-equipment/index" */))
const _5fb8dcaa = () => interopDefault(import('..\\pages\\nodes\\index.vue' /* webpackChunkName: "pages/nodes/index" */))
const _0def0a34 = () => interopDefault(import('..\\pages\\plugin\\index.vue' /* webpackChunkName: "pages/plugin/index" */))
const _841ca19a = () => interopDefault(import('..\\pages\\privacy\\index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _f4390f56 = () => interopDefault(import('..\\pages\\products\\index.vue' /* webpackChunkName: "pages/products/index" */))
const _3be3efc0 = () => interopDefault(import('..\\pages\\promise\\index.vue' /* webpackChunkName: "pages/promise/index" */))
const _0680cde3 = () => interopDefault(import('..\\pages\\quality-monitoring\\index.vue' /* webpackChunkName: "pages/quality-monitoring/index" */))
const _4fb468e1 = () => interopDefault(import('..\\pages\\realtime-sdk\\index.vue' /* webpackChunkName: "pages/realtime-sdk/index" */))
const _428abf08 = () => interopDefault(import('..\\pages\\record\\index.vue' /* webpackChunkName: "pages/record/index" */))
const _ad4ab3f8 = () => interopDefault(import('..\\pages\\satisfaction-improvement\\index.vue' /* webpackChunkName: "pages/satisfaction-improvement/index" */))
const _0180b036 = () => interopDefault(import('..\\pages\\settings\\index.vue' /* webpackChunkName: "pages/settings/index" */))
const _afbcdff0 = () => interopDefault(import('..\\pages\\smpro\\index.vue' /* webpackChunkName: "pages/smpro/index" */))
const _420e06fc = () => interopDefault(import('..\\pages\\speedtest\\index.vue' /* webpackChunkName: "pages/speedtest/index" */))
const _604d3860 = () => interopDefault(import('..\\pages\\speedtest-deployment\\index.vue' /* webpackChunkName: "pages/speedtest-deployment/index" */))
const _65134d16 = () => interopDefault(import('..\\pages\\speedtest-sdk\\index.vue' /* webpackChunkName: "pages/speedtest-sdk/index" */))
const _2c3472de = () => interopDefault(import('..\\pages\\testing-services\\index.vue' /* webpackChunkName: "pages/testing-services/index" */))
const _1242d693 = () => interopDefault(import('..\\pages\\video-sdk\\index.vue' /* webpackChunkName: "pages/video-sdk/index" */))
const _39a285af = () => interopDefault(import('..\\pages\\web-open-speed-sdk\\index.vue' /* webpackChunkName: "pages/web-open-speed-sdk/index" */))
const _7e25363e = () => interopDefault(import('..\\pages\\whole-home-wifi-sdk\\index.vue' /* webpackChunkName: "pages/whole-home-wifi-sdk/index" */))
const _3b42065a = () => interopDefault(import('..\\pages\\wifi-analysis-sdk\\index.vue' /* webpackChunkName: "pages/wifi-analysis-sdk/index" */))
const _fe379282 = () => interopDefault(import('..\\pages\\products\\cli\\index.vue' /* webpackChunkName: "pages/products/cli/index" */))
const _51db7e62 = () => interopDefault(import('..\\pages\\products\\high-bandwidth\\index.vue' /* webpackChunkName: "pages/products/high-bandwidth/index" */))
const _653d8194 = () => interopDefault(import('..\\pages\\products\\intranet\\index.vue' /* webpackChunkName: "pages/products/intranet/index" */))
const _90d3367c = () => interopDefault(import('..\\pages\\products\\plugin\\index.vue' /* webpackChunkName: "pages/products/plugin/index" */))
const _3a0be765 = () => interopDefault(import('..\\pages\\products\\sdk\\index.vue' /* webpackChunkName: "pages/products/sdk/index" */))
const _9fa88256 = () => interopDefault(import('..\\pages\\products\\speedtest-package.vue' /* webpackChunkName: "pages/products/speedtest-package" */))
const _0ed2eac3 = () => interopDefault(import('..\\pages\\products\\speedtest-package-overcount.vue' /* webpackChunkName: "pages/products/speedtest-package-overcount" */))
const _14e0625c = () => interopDefault(import('..\\pages\\settings\\close.vue' /* webpackChunkName: "pages/settings/close" */))
const _cc8d6cd6 = () => interopDefault(import('..\\pages\\products\\cli\\data\\index.vue' /* webpackChunkName: "pages/products/cli/data/index" */))
const _5632e484 = () => interopDefault(import('..\\pages\\products\\cli\\document.vue' /* webpackChunkName: "pages/products/cli/document" */))
const _621b87a5 = () => interopDefault(import('..\\pages\\products\\high-bandwidth\\data\\index.vue' /* webpackChunkName: "pages/products/high-bandwidth/data/index" */))
const _a6e8eca4 = () => interopDefault(import('..\\pages\\products\\high-bandwidth\\document.vue' /* webpackChunkName: "pages/products/high-bandwidth/document" */))
const _41bac432 = () => interopDefault(import('..\\pages\\products\\intranet\\document.vue' /* webpackChunkName: "pages/products/intranet/document" */))
const _ff78ad50 = () => interopDefault(import('..\\pages\\products\\plugin\\data\\index.vue' /* webpackChunkName: "pages/products/plugin/data/index" */))
const _4442064a = () => interopDefault(import('..\\pages\\products\\plugin\\document.vue' /* webpackChunkName: "pages/products/plugin/document" */))
const _2fa765bb = () => interopDefault(import('..\\pages\\products\\sdk\\data\\index.vue' /* webpackChunkName: "pages/products/sdk/data/index" */))
const _f2a75950 = () => interopDefault(import('..\\pages\\products\\sdk\\document.vue' /* webpackChunkName: "pages/products/sdk/document" */))
const _326bfc15 = () => interopDefault(import('..\\pages\\products\\cli\\data\\download.vue' /* webpackChunkName: "pages/products/cli/data/download" */))
const _13004405 = () => interopDefault(import('..\\pages\\products\\high-bandwidth\\data\\download.vue' /* webpackChunkName: "pages/products/high-bandwidth/data/download" */))
const _7329e172 = () => interopDefault(import('..\\pages\\products\\plugin\\data\\download.vue' /* webpackChunkName: "pages/products/plugin/data/download" */))
const _8aecb3a2 = () => interopDefault(import('..\\pages\\products\\sdk\\data\\download.vue' /* webpackChunkName: "pages/products/sdk/data/download" */))
const _06601b0e = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/appmalf-sdk",
    component: _7e11349f,
    name: "appmalf-sdk"
  }, {
    path: "/apps",
    component: _565c2bf2,
    name: "apps"
  }, {
    path: "/authentication",
    component: _5af53b81,
    name: "authentication"
  }, {
    path: "/cli",
    component: _2bbb19ca,
    name: "cli"
  }, {
    path: "/combo-sdk",
    component: _ab3cddc0,
    name: "combo-sdk"
  }, {
    path: "/commitment",
    component: _7ad31e44,
    name: "commitment"
  }, {
    path: "/compliance",
    component: _10baf97e,
    name: "compliance"
  }, {
    path: "/consulting",
    component: _37c1ee43,
    name: "consulting"
  }, {
    path: "/custom",
    component: _2275ebe8,
    name: "custom"
  }, {
    path: "/data",
    component: _54185c62,
    name: "data"
  }, {
    path: "/edge-security-acceleration",
    component: _672e1708,
    name: "edge-security-acceleration"
  }, {
    path: "/forget-password",
    component: _1bb41540,
    name: "forget-password"
  }, {
    path: "/game-sdk",
    component: _2fdd4afa,
    name: "game-sdk"
  }, {
    path: "/high-bandwidth",
    component: _41adf2f3,
    name: "high-bandwidth"
  }, {
    path: "/information",
    component: _e31242e2,
    name: "information"
  }, {
    path: "/inquiry",
    component: _52c788d8,
    name: "inquiry"
  }, {
    path: "/intranet",
    component: _7aa65c5a,
    name: "intranet"
  }, {
    path: "/management",
    component: _0be70856,
    name: "management"
  }, {
    path: "/mobile-network-sdk",
    component: _12323009,
    name: "mobile-network-sdk"
  }, {
    path: "/network-equipment",
    component: _6a01db28,
    name: "network-equipment"
  }, {
    path: "/nodes",
    component: _5fb8dcaa,
    name: "nodes"
  }, {
    path: "/plugin",
    component: _0def0a34,
    name: "plugin"
  }, {
    path: "/privacy",
    component: _841ca19a,
    name: "privacy"
  }, {
    path: "/products",
    component: _f4390f56,
    name: "products"
  }, {
    path: "/promise",
    component: _3be3efc0,
    name: "promise"
  }, {
    path: "/quality-monitoring",
    component: _0680cde3,
    name: "quality-monitoring"
  }, {
    path: "/realtime-sdk",
    component: _4fb468e1,
    name: "realtime-sdk"
  }, {
    path: "/record",
    component: _428abf08,
    name: "record"
  }, {
    path: "/satisfaction-improvement",
    component: _ad4ab3f8,
    name: "satisfaction-improvement"
  }, {
    path: "/settings",
    component: _0180b036,
    name: "settings"
  }, {
    path: "/smpro",
    component: _afbcdff0,
    name: "smpro"
  }, {
    path: "/speedtest",
    component: _420e06fc,
    name: "speedtest"
  }, {
    path: "/speedtest-deployment",
    component: _604d3860,
    name: "speedtest-deployment"
  }, {
    path: "/speedtest-sdk",
    component: _65134d16,
    name: "speedtest-sdk"
  }, {
    path: "/testing-services",
    component: _2c3472de,
    name: "testing-services"
  }, {
    path: "/video-sdk",
    component: _1242d693,
    name: "video-sdk"
  }, {
    path: "/web-open-speed-sdk",
    component: _39a285af,
    name: "web-open-speed-sdk"
  }, {
    path: "/whole-home-wifi-sdk",
    component: _7e25363e,
    name: "whole-home-wifi-sdk"
  }, {
    path: "/wifi-analysis-sdk",
    component: _3b42065a,
    name: "wifi-analysis-sdk"
  }, {
    path: "/products/cli",
    component: _fe379282,
    name: "products-cli"
  }, {
    path: "/products/high-bandwidth",
    component: _51db7e62,
    name: "products-high-bandwidth"
  }, {
    path: "/products/intranet",
    component: _653d8194,
    name: "products-intranet"
  }, {
    path: "/products/plugin",
    component: _90d3367c,
    name: "products-plugin"
  }, {
    path: "/products/sdk",
    component: _3a0be765,
    name: "products-sdk"
  }, {
    path: "/products/speedtest-package",
    component: _9fa88256,
    name: "products-speedtest-package"
  }, {
    path: "/products/speedtest-package-overcount",
    component: _0ed2eac3,
    name: "products-speedtest-package-overcount"
  }, {
    path: "/settings/close",
    component: _14e0625c,
    name: "settings-close"
  }, {
    path: "/products/cli/data",
    component: _cc8d6cd6,
    name: "products-cli-data"
  }, {
    path: "/products/cli/document",
    component: _5632e484,
    name: "products-cli-document"
  }, {
    path: "/products/high-bandwidth/data",
    component: _621b87a5,
    name: "products-high-bandwidth-data"
  }, {
    path: "/products/high-bandwidth/document",
    component: _a6e8eca4,
    name: "products-high-bandwidth-document"
  }, {
    path: "/products/intranet/document",
    component: _41bac432,
    name: "products-intranet-document"
  }, {
    path: "/products/plugin/data",
    component: _ff78ad50,
    name: "products-plugin-data"
  }, {
    path: "/products/plugin/document",
    component: _4442064a,
    name: "products-plugin-document"
  }, {
    path: "/products/sdk/data",
    component: _2fa765bb,
    name: "products-sdk-data"
  }, {
    path: "/products/sdk/document",
    component: _f2a75950,
    name: "products-sdk-document"
  }, {
    path: "/products/cli/data/download",
    component: _326bfc15,
    name: "products-cli-data-download"
  }, {
    path: "/products/high-bandwidth/data/download",
    component: _13004405,
    name: "products-high-bandwidth-data-download"
  }, {
    path: "/products/plugin/data/download",
    component: _7329e172,
    name: "products-plugin-data-download"
  }, {
    path: "/products/sdk/data/download",
    component: _8aecb3a2,
    name: "products-sdk-data-download"
  }, {
    path: "/",
    component: _06601b0e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
