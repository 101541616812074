
import {userStore, accountCheck} from "@/api/login";
import {emailReg} from "~/config/regConfig";
import {mapActions} from "vuex";
import {getConsultInfo} from "~/api/company";

export default {
  name: "Login",
  data() {
    let validateAccount = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入用户名'));
      } else if (value.length > 20 || value.length < 5) {
        callback(new Error('用户名限制5-20个字符'));
      } else {
        callback();
      }
    };
    let validatePass = (rule, value, callback) => {
      let reg = /^[\S]*$/;
      let reg2 = /^(?![0-9]+$)(?![a-zA-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-zA-Z]|[0-9]){6,20}$/
      if (!value) {
        callback(new Error('请输入密码'));
      } else if (!reg.test(value)) {
        callback(new Error('密码不允许存在空格'));
      } else if (!reg2.test(value)) {
        callback(new Error('请设置6-20位字母/数字/符号两种以上的组合密码'));
      } else {
        callback();
      }
    };
    let validateConfirmPass = (rule, value, callback) => {
      let reg = /^[\S]*$/;
      let reg2 = /^(?![0-9]+$)(?![a-zA-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-zA-Z]|[0-9]){6,20}$/
      if (!value) {
        callback(new Error('请输入确认密码'));
      } else if (!reg.test(value)) {
        callback(new Error('密码不允许存在空格'));
      } else if (!reg2.test(value)) {
        callback(new Error('请设置6-20位字母/数字/符号两种以上的组合密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('确认密码不一致'));
      } else {
        callback();
      }
    };
    let validateMail = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入邮箱'));
      } else if (!emailReg.test(value)) {
        callback(new Error('邮箱格式不正确'));
      } else {
        callback();
      }
    };
    return {
      accountErrorMsg: '',
      mailErrorMsg: '',
      formValidatorAccount: true,
      formValidatorMail: true,
      ruleForm: {
        account: '',
        mail: '',
        password: '',
        confirmPass: '',
      },
      rules: {
        account: [
          {required: true, validator: validateAccount, trigger: 'blur'},
        ],
        // mail: [
        //   {required: true, validator: validateMail, trigger: 'blur'},
        // ],
        password: [
          {required: true, validator: validatePass, trigger: 'change'},
        ],
        confirmPass: [
          {required: true, validator: validateConfirmPass, trigger: 'blur'},
        ]
      }
    }
  },
  methods: {
    ...mapActions(['setShowInquiryAlert']),
    checkAccount(event) { //验证用户名是否已经存在
      this.accountErrorMsg = '';
      this.$refs.ruleForm.validateField('account', (check) => { // 判断用户名
        if (check === '') {
          accountCheck({type: 'account', account: this.ruleForm.account}).then(res => {
            if (res.code === 0) {
              if (res.data.exist) {
                this.accountErrorMsg = '该手机号已注册帐号';
                this.formValidatorAccount = false;
              } else {
                this.formValidatorAccount = true;
              }
            }
          })
        }
      })
    },
    checkMail(event) { //验证邮箱是否已经存在
      this.mailErrorMsg = '';
      this.$refs.ruleForm.validateField('mail', (check) => { // 判断邮箱
        if (check === '') {
          accountCheck({type: 'mail', account: this.ruleForm.mail}).then(res => {
            if (res.code === 0) {
              if (res.data.exist) {
                this.mailErrorMsg = '该邮箱已注册帐号';
                this.formValidatorMail = false;
              } else {
                this.formValidatorMail = true;
              }
            }
          })
        }
      })
    },
    /**
     * 确定
     */
    submitForm() {
      // this.checkMail();
      this.checkAccount();
      this.$refs['ruleForm'].validate((valid) => {
        if (valid  && this.formValidatorAccount) {
          userStore(this.ruleForm).then(res => {
            if (res.code === 0) {
              // window.location.href = location.href;
              getConsultInfo({phone:this.$store.state.notRegisterAlert}).then(res=>{
                this.$store.commit('NOT_REGISTER_ALERT', false);
                if(res.data){
                  this.setShowInquiryAlert(res.data.phone)
                }else {
                  location.href='/authentication'
                }
              })
            }
          })
        } else {
          return false;
        }
      });
    },
    /**
     * 关闭登录
     * */
    close() {
      this.$store.commit('LOGIN_ALERT', false);
    }
  }
}
