import request from '@/utils/request';
import { COMPANY_INFO_URL,
  COMPANY_STORE_URL,
  UPLOAD_URL,
  CONSULT_APPLY_URL,
  CONSULT_INFO_URL,
  INFO_APPROVED_URL,
} from './api-list';

// 公司信息详情
export function companyInfo() {
  return request({
    url: COMPANY_INFO_URL,
    method: 'get',
  });
}

// 保存公司信息
export function companyStore(params) {
  return request({
    url: COMPANY_STORE_URL,
    method: 'post',
    params: params
  });
}

// 文件上传
export function upload(params) {
  return request({
    url: UPLOAD_URL,
    method: 'post',
    params: params,
    queryType: 'file'
  });
}

// 咨询申请
export function postConsultApply(params) {
  return request({
    url: CONSULT_APPLY_URL,
    method: 'post',
    params: params
  });
}

// 咨询申请
export function getConsultInfo(params) {
  return request({
    url: CONSULT_INFO_URL,
    method: 'get',
    params: params
  });
}

// 最新审核过的公司信息
export function getInfoApproved(params) {
  return request({
    url: INFO_APPROVED_URL,
    method: 'post',
    params: params
  });
}
