/**
 *
 * 手机号脱敏处理
 */
export const noPassByPhone = (phone) => {
  return phone.substring(0, 3) + "****" + phone.substring(7);
}

/**
 *
 * 邮箱脱敏处理
 */
export const noPassByEmail = (email) => {
  let new_email = email;
  if (String(email).indexOf('@') > 0) {
    let str = email.split('@');
    let _s = '';
    if (str[0].length > 3) { //@前面多于3位
      for (let i = 3; i < str[0].length; i++) {
        _s += '*';
      }
      new_email = str[0].substr(0, 3) + _s + '@' + str[1];
    } else { //@前面小于等于于3位
      for (let i = 1; i < str[0].length; i++) {
        _s += '*'
      }
      new_email = str[0].substr(0, 1) + _s + '@' + str[1]
    }
  }
  return new_email;
}

/***
 * 判断会员是否过期
 * @parse {string} vip_expire 根据vip_expire判断会员是否过期以及是否是会员
 * */
export const getExpire = (vip_expire) => {
  let expire;
  if (vip_expire) {
    expire = vip_expire.replace(/-/g, "/");
  }
  if (expire && new Date(expire).getTime() > new Date().getTime()) {
    return false;
  } else {
    return true;
  }
};

//获取上个月的年月
export const getYearAndMonth = () => {
  var date = new Date;
  var year = date.getFullYear();
  var month = date.getMonth();
  if (month == 0) {
    year = year - 1;
    month = 12;
  }
  return {
    year,
    month
  }
}


//数字千分符
export const format = (num) => {
  return (num + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1，');
}

//获取距档期凌晨的时间差
export const getToWeeNum = (data, today) => {
  let num1 = data * 1000;
  let num2 = today * 1000;
  // let weeData = new Date(num);
  // weeData.setHours(0,0,0,0)
  return 86400000 - (num1 - num2);
}


// 判断某一时间到今天的天数
export const getDistanceSpecifiedDay = (dateTime) => {
  let expire
  if (dateTime) {
    expire = dateTime.replace(/-/g, '/')
  }
  // 指定日期和时间
  var EndTime = new Date(expire)
  // 当前系统时间
  var NowTime = new Date()
  var t = EndTime.getTime() - NowTime.getTime()
  var d = Math.floor(t / 1000 / 60 / 60 / 24)
  var h = Math.floor(t / 1000 / 60 / 60 % 24)
  var m = Math.floor(t / 1000 / 60 % 60)
  var s = Math.floor(t / 1000 % 60)
  var htmlTisu = '';
  var html = '';
  if (d > 0) {
    html += d + '天';
    htmlTisu += d + ' 天 ';
  }
  if (h > 0) {
    html += h + '小时';
    htmlTisu += h + '小时';
  }
  // if(m>0){
  //     html+=m + '分钟';
  // }
  return {
    day: d,
    hour: h,
    time: html ? html : '小于1小时',
    html: htmlTisu ? htmlTisu : '小于1小时'
  };
}

//判断是否是IE
export const isEdge = () => {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  if (userAgent.indexOf("Edge") > -1) {
    return true;
  }
  return false;
}

//获取距文档上方距离
export const getAbsTop = (obj) => {
  if (!obj) return;
  var top = obj.offsetTop;
  while (obj = obj.offsetParent) {
    top += obj.offsetTop;
  }
  return top;
}

//获取距文档上方距离
export const getAbsLeft = (obj) => {
  var left = obj.offsetLeft;
  while (obj = obj.offsetParent) {
    left += obj.offsetLeft;
  }
  return left;
}

// 根据运营商增加前缀
export const ispPrefix = (reqIsp) => {
  if(reqIsp===undefined){
    return ''
  }
  if (!reqIsp) {
    return '-';
  }
  let preFixList = ['电信', '联通', '移动', '广电'];
  if (preFixList.includes(reqIsp)) {
    return `中国${reqIsp}`
  }
  return reqIsp;
}

// 区分delay以及iqm
export const divisionDelayIqm = (reqDelay, reqIqm) => {
  if (reqIqm !== null) {
    return reqIqm
  }
  return reqDelay == '-1' ? null : reqDelay;
}

/**
 * 获取加测文案index
 * */
export const getAddTestResIndex = (val, type) => {
  if (type === 'web'||type === 'web-add') {
    if (val <= 1) {
      return 0;
    } else if (val <= 2) {
      return 1;
    } else if (val <= 6) {
      return 2;
    } else {
      return 3;
    }
  } else {
    if (val <= 50) {
      return 0;
    } else if (val <= 100) {
      return 1;
    } else if (val <= 200) {
      return 2;
    } else {
      return 3;
    }
  }
}

export const gotoLoginAfterUrlFn=()=>{
  let loginSuccessAfterUrl=sessionStorage.getItem('loginSuccessAfterUrl')||''
  if(loginSuccessAfterUrl){
    sessionStorage.removeItem('loginSuccessAfterUrl')
    window.location.href = loginSuccessAfterUrl
  } else {
    window.location.href = location.href;
  }
}
