import request from '@/utils/request';
import {
  SPEEDTIMES_EXCESSCOUNT_URL,
  USER_INFO_URL,
  USER_PRODUCTS_URL,
  SPEEDTIMES_MY_URL,
  PLUGIN_MY_URL,
  SDK_MY_URL,
  CLI_MY_URL,
  LAN_MY_URL,
  LARGE_BROADBAND_MY_URL,
  GET_SPEEDATA_URL,
  BATCH_TASK_STORE_URL,
  BATCH_TASK_MY_URL,
  SPEED_DATA_DETAIL,
  PRODUCT_LIST_URL, GET_TRIAL_TIME_URL,
} from './api-list';

// 用户信息
export function userInfo() {
  return request({
    url: USER_INFO_URL,
    method: 'get',
  });
}

// 我的产品
export function userProducts() {
  return request({
    url: USER_PRODUCTS_URL,
    method: 'get',
  });
}

// 我的测速包
export function mySpeedTimes(params) {
  return request({
    url: SPEEDTIMES_MY_URL,
    // url:,
    method: 'get',
    params: params
  });
}

// 我的测速包
export function mySpeedTimesOverseas(params) {
  return request({
    url: SPEEDTIMES_MY_URL,
    // url:,
    method: 'get',
    params: params,
    baseUrlType:4
  });
}

// 我的测速包ID超额统计
export function mySpeedTimesExcessCount(params) {
  return request({
    url: SPEEDTIMES_EXCESSCOUNT_URL,
    // url:,
    method: 'get',
    params: params
  });
}

// 我的测速包ID超额统计
export function mySpeedTimesExcessCountOverseas(params) {
  return request({
    url: SPEEDTIMES_EXCESSCOUNT_URL,
    // url:,
    method: 'get',
    params: params,
    baseUrlType:4
  });
}

// 我的插件
export function myPlugin(params) {
  return request({
    url: PLUGIN_MY_URL,
    method: 'get',
    params: params
  });
}

// 我的sdk
export function mySdk(params) {
  return request({
    url: SDK_MY_URL,
    method: 'get',
    params: params,
  });
}

// 我的sdk海外版
export function mySdkOverseas(params) {
  return request({
    url: SDK_MY_URL,
    method: 'get',
    params: params,
    baseUrlType:4
  });
}

// 我的命令行测速
export function myCli(params) {
  return request({
    url: CLI_MY_URL,
    method: 'get',
    params: params
  });
}

// 我的命令行测速
export function myCliOverseas(params) {
  return request({
    url: CLI_MY_URL,
    method: 'get',
    params: params,
    baseUrlType:4
  });
}

// 我的内网测速
export function myLan(params) {
  return request({
    url: LAN_MY_URL,
    method: 'get',
    params: params
  });
}

// 我的大带宽检测
export function myLargeBroadband(params) {
  return request({
    url: LARGE_BROADBAND_MY_URL,
    method: 'get',
    params: params
  });
}

// 测速数据列表
export function getSpeedData(params) {
  return request({
    url: GET_SPEEDATA_URL,
    method: 'get',
    params: params
  });
}


// 测速数据列表
export function getSpeedDataOverseas(params) {
  return request({
    url: GET_SPEEDATA_URL,
    method: 'get',
    params: params,
    baseUrlType:4
  });
}

// 数据导出任务
export function batchTaskStore(params) {
  return request({
    url: BATCH_TASK_STORE_URL,
    method: 'post',
    params: params
  });
}

// 数据导出任务
export function batchTaskStoreOverseas(params) {
  return request({
    url: BATCH_TASK_STORE_URL,
    method: 'post',
    params: params,
    baseUrlType:4
  });
}

// 数据导出列表
export function batchTaskMy(params) {
  return request({
    url: BATCH_TASK_MY_URL,
    method: 'get',
    params: params
  });
}

// 数据导出列表
export function batchTaskMyOverseas(params) {
  return request({
    url: BATCH_TASK_MY_URL,
    method: 'get',
    params: params,
    baseUrlType:4
  });
}

// 数据导出列表
export function getSpeedDataDetail(params) {
  return request({
    url: SPEED_DATA_DETAIL,
    method: 'get',
    params: params
  });
}
// 数据导出列表
export function getSpeedDataDetailOverseas(params) {
  return request({
    url: SPEED_DATA_DETAIL,
    method: 'get',
    params: params,
    baseUrlType: 4
  });
}

// 获取产品列表统计
export function getProductList() {
  return request({
    url: PRODUCT_LIST_URL,
    method: 'post',
  });
}

// 获取产品列表统计海外版
export function getProductListOverseas() {
  return request({
    url: PRODUCT_LIST_URL,
    method: 'post',
    baseUrlType:4
  });
}

//网维助手获取免费时长
export function getTrialTimeInfo() {
  return request({
    url: GET_TRIAL_TIME_URL,
    method: 'get',
    baseUrlType:1
  });
}
