
export default {
    name: "authentication",
    data() {
        return {
        }
    },
    methods: {
        authentication(){
            this.close();
            this.$router.push({path:'/authentication'})
        },
        close(){
            this.$store.commit('AUTHENTICATION_ALERT', false);
        }
    },
}
