
import { loginRegister, smsSend, accountCheck } from "@/api/login";
import { setToken } from '@/utils/auth';
import {myTencentCaptcha} from "~/utils/myTencentCaptcha";
import {phoneReg} from "~/config/regConfig";
import {getConsultInfo} from "~/api/company";
import {mapActions} from "vuex";
export default {
    name: "Login",
    data(){
        let validateAccount = (rule, value, callback) => {
            if (!value ) {
                callback(new Error('请输入用户名'));
            } else if (value.length>20 || value.length<5){
                callback(new Error('用户名限制5-20个字符'));
            }else {
                callback();
            }
        };
        let validatePhone = (rule, value, callback) => {
            let reg = phoneReg;
            if (!value ) {
                callback(new Error('请输入手机号'));
            } else if (!reg.test(value)){
                callback(new Error('请输入正确的手机号'));
            }else {
                callback();
            }
        };
        let validatePass = (rule, value, callback) => {
            let reg = /^[\S]*$/;
            let reg2 = /^(?![0-9]+$)(?![a-zA-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-zA-Z]|[0-9]){6,20}$/
            if (!value ) {
                callback(new Error('请输入密码'));
            } else if (!reg.test(value)){
                callback(new Error('密码不允许存在空格'));
            }else if (!reg2.test(value)){
                callback(new Error('请设置6-20位字母/数字/符号两种以上的组合密码'));
            }else {
                callback();
            }
        };
        let validateConfirmPass = (rule, value, callback) => {
            let reg = /^[\S]*$/;
            let reg2 = /^(?![0-9]+$)(?![a-zA-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-zA-Z]|[0-9]){6,20}$/
            if (!value ) {
                callback(new Error('请输入确认密码'));
            } else if (!reg.test(value)){
                callback(new Error('密码不允许存在空格'));
            } else if (!reg2.test(value)){
                callback(new Error('请设置6-20位字母/数字/符号两种以上的组合密码'));
            } else if (value !== this.ruleForm.password){
                callback(new Error('确认密码不一致'));
            } else {
                callback();
            }
        };
        return{
            btnLoading: false,//登录按钮loading
            accountErrorMsg: '',//用户名错误提示信息
            phoneErrorMsg: '',//手机号错误提示信息
            codeErrorMsg: '',//验证码错误提示信息
            // formValidatorAccount: true,
            // formValidatorPhone: true,
            checked: false,
            codeBtnText: "发送验证码",//验证码按钮文字说明
            interval: "",//倒计时
            ruleForm: {
                account: '',
                phone: '',
                password: '',
                confirmPass: '',
                code: '',
            },
            rules:{
                account:[
                    { required: true, validator: validateAccount, trigger: 'blur' },
                ],
                phone:[
                    { required: true, validator: validatePhone, trigger: 'blur' },
                ],
                code:[
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                ],
                password:[
                    { required: true, validator: validatePass, trigger: 'change' },
                ],
                confirmPass:[
                    { required: true, validator: validateConfirmPass, trigger: 'blur' },
                ]
            },
            window:null
        }
    },
    methods:{
      ...mapActions(['setShowInquiryAlert']),
        checkAccount(event){ //验证用户名是否已经存在
        return new Promise((resolve)=>{
          this.accountErrorMsg = '';
          this.$refs.ruleForm.validateField('account', (check) => { // 判断用户名
            if(check === ''){
              accountCheck({type: 'account', account: this.ruleForm.account }).then(res=>{
                if(res.code === 0){
                  if(res.data.exist){
                    this.accountErrorMsg = '用户名已存在';
                    // this.formValidatorAccount = false;
                    resolve(false)
                  } else {
                    // this.formValidatorAccount = true;
                    resolve(true)
                  }
                  // resolve()
                }
              })
            }
          })
        })
        },
        checkPhone(event){ //验证手机号是否已经存在
          return new Promise((resolve)=>{
            this.phoneErrorMsg = '';
            this.$refs.ruleForm.validateField('phone', (check) => { // 判断手机号
              if(check === ''){
                accountCheck({type: 'phone', account: this.ruleForm.phone }).then(res=>{
                  if(res.code === 0){
                    if(res.data.exist){
                      this.phoneErrorMsg = '该手机号已注册帐号';
                      // this.formValidatorPhone = false;
                      resolve(false)
                    } else {
                      // this.formValidatorPhone = true;
                      resolve(true)
                    }
                  }
                })
              }
            })
          })

        },
        /**
         * 登录
         */
        async submitForm(){
          if(!this.btnLoading){
            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid &&await this.checkAccount() && await this.checkPhone() ) {
                  if(!this.checked){
                      this.$message({
                          message: '请勾选《用户协议》、《隐私政策》和《产品使用承诺书》',
                          type: 'warning',
                      });
                      return;
                  } else {
                    this.toLoginRegisterFn()
                  }
                } else {
                    return false;
                }
            });
          }

        },
      //注册并登录
      toLoginRegisterFn(){
        this.btnLoading = true;
        loginRegister(this.ruleForm).then(res => {//注册并且登录
          if(res.code === 0){
            setToken(res.data.token);
            this.$store.commit('LOGIN_ALERT', false);
            getConsultInfo({phone:this.ruleForm.phone}).then(res=>{
              if(res.data){
                this.setShowInquiryAlert(res.data.phone)
                this.close()
              }else {
                  location.href='/authentication'
              }
            })
          } else {
            this.codeErrorMsg = res.msg;
          }
        }).finally(()=>{
          this.btnLoading = false;
        })
      },
        /**
         * 去登录
         */
        goLogin(){
            this.$store.commit('REGISTER_ACCOUNT_ALERT', false);
            this.$store.commit('LOGIN_ALERT', true);
        },
        /**
         * 关闭登录
         * */
        close() {
            this.$store.commit('REGISTER_ACCOUNT_ALERT', false);
        },
        /**
         * 获取验证码
         * */
        getCode() {

            if((this.codeBtnText==='发送验证码'||this.codeBtnText==='重新发送')){
                this.phoneErrorMsg = '';
                this.$refs.ruleForm.validateField('phone', (check) => { // 判断手机号
                    if(check == ''){
                      if(!this.checked){
                        this.$message({
                          message: '请勾选《用户协议》、《隐私政策》和《产品使用承诺书》',
                          type: 'warning',
                        });
                        return;
                      }
                      accountCheck({type: 'phone', account: this.ruleForm.phone }).then(res=>{
                        if(res.code === 0){
                          if(res.data.exist){
                            this.phoneErrorMsg = '该手机号已注册帐号';
                            this.formValidatorPhone = false;
                          } else {
                            let captcha = myTencentCaptcha( res => {
                              if (res.ret === 0) {
                                smsSend({
                                    phone: this.ruleForm.phone,
                                    ticket: res.ticket,
                                    randstr: res.randstr
                                }).then(res => {
                                    this.countdown();
                                }).catch(() => {
                                    this.codeErrText = "获取验证码失败，请重试";
                                })
                              }
                            });
                            captcha.show();
                          }
                        }
                      })
                    } else {
                    }
                })
            }
        },
        /**
        * 进行倒计时操作
        * */
        countdown() {
            let initNum = 60;
            this.codeBtnText = `重新发送(${initNum}s)`;
            this.interval = setInterval(() => {
                if (!initNum) {
                    clearInterval(this.interval);
                    this.codeBtnText = "重新发送";
                    return;
                }
                this.codeBtnText = `重新发送(${initNum}s)`;
                initNum--;
            }, 1000)
        },
      goToLink(url) {
        // this.$router.push(url)
        window.open(this.window.location.origin + url)
      }
    },
    mounted(){
      this.window = window
    }
}
