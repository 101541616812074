import CryptoJS from 'crypto-js';
/**
 * 对数据进行aes解密
 * @param {JSON} data
 * @return {JSON}
 * */
export const aesDeciphering = (data) => {
    let key = "5ECC5D62140EC099";
    let iv = "E63EA892A702EEAA";
    const keyUTF8 = CryptoJS.enc.Utf8.parse(key);
    const offsetUTF8 = CryptoJS.enc.Utf8.parse(iv);
    const bytes = CryptoJS.AES.decrypt(data, keyUTF8, {
        iv: offsetUTF8,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    try{
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }catch(error){
      return bytes.toString(CryptoJS.enc.Utf8);
    }
}
