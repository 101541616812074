
import {mapActions, mapState} from "vuex";
import {gotoLoginAfterUrlFn, noPassByPhone} from "~/utils/globalFunction";

export default {
  name: "reapply",
  data() {
    return {
      email: 'bd@speedtest.cn',
      clipboard: null,
    }
  },
  methods: {
    ...mapActions(['setShowInquiryAlert']),
    noPassByPhone,
    gotoLink(){
      this.setShowInquiryAlert(false)
      location.href='/information?isInquiry=true'
    },
    close(){
      gotoLoginAfterUrlFn()
    }
  },
}
