
import Clipboard from 'clipboard';

export default {
    name: "reapply",
    methods: {
        close(){
            this.$store.commit('REAPPLY_ALERT', false);
        }
    },
}
