import {txCaptchaId} from '@/config/tencentCaptchaData'
/**
 * 腾讯防水墙
 * */
export const myTencentCaptcha = (myFn) => {
   return new TencentCaptcha(txCaptchaId,
        myFn,{
       needFeedBack:false
     }
   );
}
