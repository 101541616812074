// 接口进行校验

export const serviceCheck = (method, data, url) => {
  const { getParams, postParams } = changeData(method, data, url);
  const timestamp = Date.parse(new Date()) / 1000;
  let getStr = '';
  if (JSON.stringify(getParams) !== '{}' && getParams) {
    for (const key1 in getParams) {
      if (getParams[key1] !== undefined && getParams[key1] !== null) {
        getStr += '&' + key1 + '=' + encodeURIComponent(getParams[key1]);
      }
    }
    getStr = getStr.substr(1, getStr.length);
  }
  let postStr = '';
  if (JSON.stringify(postParams) !== '{}' && postParams) {
    for (const key2 in postParams) {
      if (postParams[key2] !== undefined && postParams[key2] !== null) {
        postStr += '&' + key2 + '=' + encodeURIComponent(postParams[key2]);
      }
    }
    postStr = postStr.substr(1, postStr.length);
  }
  return {
    timestamp
  };
};

// 修改data
const changeData = (method, data, url) => {
  let getParams;
  let postParams = null;
  if (method === 'get' && JSON.stringify(data) !== '{}') {
    getParams = data;
  }

  if (method === 'post' && JSON.stringify(data) !== '{}') {
    postParams = data;
    getParams = getRequest(url);
  }
  return { getParams, postParams };
};
// 获取get请求的参数
const getRequest = (urlStr) => {
  let url = null;
  if (urlStr.split('?').length <= 1) {
    return {};
  }
  if (typeof urlStr === 'undefined') {
    url = decodeURI(location.search); // 获取url中"?"符后的字符串
  } else {
    url = '?' + urlStr.split('?')[1];
  }
  // eslint-disable-next-line no-new-object
  const theRequest = new Object();
  if (url.indexOf('?') !== -1) {
    const str = url.substr(1);
    const strs = str.split('&');
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1]);
    }
  }
  return theRequest;
};
