import axios from 'axios';
import {Message} from 'element-ui';
import {serviceCheck} from './service-check';
import {getToken} from '@/utils/auth';
import qs from 'qs';
import {baseUrl, baseUrlV3, baseProUrl, appApiUrl, hwBaseUrl} from '@/api/api-list';
import {encryptJson} from "~/utils/encryptJson";
import {aesDeciphering} from "~/utils/aesDeciphering";
import {setConfig, setResponse} from '@/utils/configSetting'

const service = axios.create({
  // baseURL: baseUrl(),
  timeout: 10000, // request timeout
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    ENCRYPT: true,
  },
  withCredentials: true,
  crossDomain: true // 设置跨域为true
});

// request interceptor
service.interceptors.request.use(
  config => {
    const {method, params, data, url, queryType} = config;
    let baseUrlType = config.hasOwnProperty('baseUrlType') ? config.baseUrlType : 0; // 基本请求地址，0为基础的 1为专业版的
    if (getToken()) {
      config.headers['Authorization'] = `Bearer ${getToken()}`;
    }
    // 判断传输是get还是post
    if (!config.method || config.method.toLowerCase() === 'get') {
      config.method = 'get'
    }
    // 取传值
    let datas = (params || data)
    // 不是文件并且有传值，加密传输
    if (queryType !== 'file' && datas) {
      if (config.method === 'get') {
        config.params = {data: encryptJson(datas)}
        config.data = null
      } else {
        config.data = qs.stringify({data: encryptJson(datas)})
        config.params = null
      }
    } else {
      config.data = datas;
    }
    const {timestamp} = serviceCheck(method, params, url);
    config.headers['timestamp'] = timestamp;
    config.url = (baseUrlType === 1 ? baseProUrl : baseUrlType === 2 ? baseUrlV3 : baseUrlType === 3 ? appApiUrl :baseUrlType === 4 ? hwBaseUrl: baseUrl) + config.url;
    if (method === 'post') {
      delete config['params'];
    }
    config = setConfig(config)
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  response => {
    let res = response.data;
    if (res && res !== undefined && res !== null) {
      if (res.code && res.code !== 401 && res.code !== 1000) {
        Message.closeAll();
        Message({
          message: res.msg || '连接超时，请重试',
          type: 'error',
          duration: 5 * 1000
        });
        return Promise.reject(new Error(res));
      } else {
        //接口是否整体加密
        res = setResponse(response)
        // 数据解密
        if (res.data && Object.prototype.toString.call(res.data) === '[object Object]') {
          if (res.data.encryptPhone) {
            res.data.phone = aesDeciphering(res.data.encryptPhone) + ''
          }
          if (res.data.encryptRealName) {
            console.log('res.data.encryptRealName ois',res.data.encryptRealName)
            res.data.realName = aesDeciphering(res.data.encryptRealName)
            console.log(' res.data.realName is', res.data.realName)
          }
          if (res.data.encryptAddress) {
            res.data.address = aesDeciphering(res.data.encryptAddress)
          }
          if (res.data.encryptMail) {
            res.data.mail = aesDeciphering(res.data.encryptMail)
          }
          if (res.data.encryptEmail) {
            res.data.email = aesDeciphering(res.data.encryptEmail)
          }
          if (res.data.encryptAccount) {
            res.data.account = aesDeciphering(res.data.encryptAccount)
          }
        }
        return res;
      }
    } else {
      return res;
    }
  },
  error => {
    Message.closeAll();
    Message({
      message: '连接超时，请重试',
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);
export default service;
