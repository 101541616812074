import request from '@/utils/request';
import { SMS_SEND_URL, LOGIN_PHONE_URL, LOGIN_REGISTER_URL, LOGIN_ACCOUNT_CHECK_URL, ACCOUNT_LOGIN_URL, USER_STORE_URL, SMS_SEND_EMAIL_URL } from './api-list';

// 短信验证码
export function smsSend(params) {
  params.captchaCloud=1
  return request({
    url: SMS_SEND_URL,
    method: 'post',
    params: params
  });
}

// 邮箱验证码
export function sendEmail(params) {
  params.captchaCloud=1
  return request({
    url: SMS_SEND_EMAIL_URL,
    method: 'post',
    params: params
  });
}

// 验证码登陆
export function loginPhone(params) {
  return request({
    url: LOGIN_PHONE_URL,
    method: 'post',
    params: params
  });
}

// 注册
export function loginRegister(params) {
  return request({
    url: LOGIN_REGISTER_URL,
    method: 'post',
    params: params
  });
}

// 验证是否已注册
export function accountCheck(params) {
  return request({
    url: LOGIN_ACCOUNT_CHECK_URL,
    method: 'post',
    params: params
  });
}

// 账号登录
export function accountLogin(params) {
  return request({
    url: ACCOUNT_LOGIN_URL,
    method: 'post',
    params: params
  });
}

// 自动注册完善信息
export function userStore(params) {
  return request({
    url: USER_STORE_URL,
    method: 'post',
    params: params
  });
}
