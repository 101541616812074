const VUE_APP_TITLE = process.env.VUE_APP_TITLE;
/**
 * 接口地址
 */
export const baseUrl = VUE_APP_TITLE === 'test' ? 'https://b-api-test.speedtest.cn/' : (VUE_APP_TITLE === 'pre' ? 'https://b-api-pre.speedtest.cn/' : 'https://b-api.speedtest.cn/');

/**
 * 接口地址V3
 */
export const baseUrlV3 =  VUE_APP_TITLE === 'test' ? 'https://api-v3-test.speedtest.cn/' : (VUE_APP_TITLE === 'pre' ? 'https://api-v3-pre.speedtest.cn/' : 'https://api-v3.speedtest.cn/');


/**
 * app拆分
 */
export const appApiUrl = VUE_APP_TITLE === 'test' ? 'https://app-api-test.speedtest.cn/'
    : (VUE_APP_TITLE === 'pre' ? 'https://app-api-pre.speedtest.cn/' : 'https://app-api.speedtest.cn/');

/**
 * 专业版接口地址
 * */
export const baseProUrl = VUE_APP_TITLE === 'test' ? 'https://pro-api-test2.speedtest.cn' : (VUE_APP_TITLE === 'pre' ? 'https://pro-api-pre.speedtest.cn' : 'https://pro-api.speedtest.cn');


/**
 * plugin内嵌地址
 * */
export const pluginUrl =  VUE_APP_TITLE === 'test' ? 'https://plugin-test.speedtest.cn' : (VUE_APP_TITLE === 'pre' ? 'https://plugin-pre.speedtest.cn' : 'https://plugin.speedtest.cn');


/**
 * 企业专线内嵌地址
 * */
export const cstUrl = VUE_APP_TITLE === 'test' ? 'https://cst-test.speedtest.cn' : (VUE_APP_TITLE === 'pre' ? 'https://cst-pre.speedtest.cn' : 'https://cst.speedtest.cn');

/**
 * 大数据地址
 * */
export const bigdataUrl = VUE_APP_TITLE === 'test' ? 'https://bigdata-test.speedtest.cn/' : (VUE_APP_TITLE === 'pre' ? 'https://bigdata-pre.speedtest.cn/' : 'https://bigdata.speedtest.cn/');
/**
 * 海外版请求地址
 * */
export const hwBaseUrl=VUE_APP_TITLE === 'test' ? 'https://hw-b-api-test.speedtest.cn/' : (VUE_APP_TITLE === 'pre' ? 'https://hw-b-api-pre.speedtest.cn/' : 'https://hw-b-api.speedtest.cn');

export const SMS_SEND_URL = '/captcha/sms'; // 短信验证码
export const SMS_SEND_EMAIL_URL = '/captcha/email'; // 邮箱验证码
export const LOGIN_REGISTER_URL = '/login/register'; // 注册
export const USER_STORE_URL = '/user/store'; // 自动注册完善信息
export const LOGIN_ACCOUNT_CHECK_URL = '/login/accountCheck'; // 验证是否已注册
export const LOGIN_PHONE_URL = '/login/phone'; // 验证码登陆
export const ACCOUNT_LOGIN_URL = '/login/accountLogin'; // 账号登录
export const CHECK_PHONE_MAIL_URL = '/login/checkPhoneMail'; // 忘记密码手机号邮箱验证
export const RESET_PASSWORD_URL = '/login/resetPassword'; // 重置密码


export const EDIT_PASS_URL = '/account/editPassword'; // 修改密码
export const USER_LOGOUT_URL = '/user/delete'; // 注销申请提交


export const USER_INFO_URL = '/user/info'; // 用户信息
export const USER_PRODUCTS_URL = '/product/my'; // 我的产品

export const COMPANY_INFO_URL = '/company/info'; // 公司信息详情
export const COMPANY_STORE_URL = '/company/store'; // 保存公司信息
export const UPLOAD_URL = '/upload'; // 文件上传

export const SPEEDTIMES_MY_URL = '/speedTimes/my'; // 我的测速包
export const SPEEDTIMES_EXCESSCOUNT_URL = '/speedTimes/excessCount'; // 我的测速包超额
export const PLUGIN_MY_URL = '/plugin/my'; // 我的插件
export const SDK_MY_URL = '/sdk/my'; // 我的sdk
export const CLI_MY_URL = '/cli/my'; // 我的cli
export const LAN_MY_URL = '/lan/my'; // 我的内网测速
export const LARGE_BROADBAND_MY_URL = '/largeBroadband/my'; // 我的大宽带测速
export const GET_SPEEDATA_URL = '/SpeedData/index'; // 测速数据列表
export const BATCH_TASK_STORE_URL = '/batchTask/store'; // 数据导出任务
export const BATCH_TASK_MY_URL = '/batchTask/my'; // 数据导出列表


export const PING_RESULT_URL = '/PingResult/result'; // 测速app列表

export const STATISTICS_URL = 'statistics'; // 埋点

export const SDK_VERSION_URL = 'SdkVersion/index'; // 集成文档

export const PARTNER_URL = 'partner'; // 合作伙伴

export const IP_URL = '/ip/index'; // 获取ip对应信息

export const DOC_URL = '/doc/index'; // 获取ip对应信息


export const PRO_APP_VERSION = '/appVersion/latest'; // 专业版app/exe下载地址

export const PRO_APP_GET_PRICE = '/setting/getPrice'; // 专业版获取会员价格

export const TOOL_WEBSITE_GET_WEBSITE = '/tool/website/getWebsite'; // 专业版获取会员价格

export const SPEED_DATA_DETAIL = '/SpeedData/detail'; // 根据ID获取sdk或插件记录详细信息

export const APP_LIST_URL = 'appVersion/appList'; // app拆分的列表

export const SPEED_MY_URL = 'speed/my'; // 专线测速的列表

export const SPEED_DETAIL_URL = 'speed/detail'; // 专线测速的详情

export const HOME_BANNER_URL = 'home/banner'; // 专线测速的轮播图

export const CONSULT_APPLY_URL = 'consult/apply'; // 立即咨询的申请
export const PRODUCT_LIST_URL = '/product/productList' //获取产品列表统计



export const CONSULT_INFO_URL = 'consult/info'; // 立即咨询的获取

export const INFO_APPROVED_URL = 'company/infoApproved'; // 最新审核过的公司信息

export const GET_TRIAL_TIME_URL = '/setting/getTrialTimeInfo'; // 获取免费时长


export const OSS_PRIVATE_URL = 'privateFile'


