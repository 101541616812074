// 统一处需要加密的接口CLIENTECTYPE66
import {interfaceEncryptionLists} from '@/config/CLIENTECTYPE66'
import {aesDeciphering} from "~/utils/aesDeciphering";
export const setConfig = (config) => {
  if(config&&config.url) {
    const isHave  = interfaceEncryptionLists.some(item=>{
      return config.url.includes(item)
    })
    if(isHave) {
      config.headers['CLIENTECTYPE'] = 66
    }
  }
  return config
}

export const  setResponse = (response) => {
  let  config = response.config
  let res = response.data
  if(config&&config.url) {
    const isHave  = interfaceEncryptionLists.some(item=>{
      return config.url.includes(item)
    })
    if(isHave) {
      try {
        //console.log(res.data)
        res.data = aesDeciphering(res.data)

      } catch (e) {

      }
    }
  }



  return response.data
}
