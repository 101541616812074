
import { accountLogin } from "@/api/login";
import { setToken } from '@/utils/auth';
import {gotoLoginAfterUrlFn} from "~/utils/globalFunction";

export default {
    data() {
        return {
            btnLoading: false,
            codeErrorMsg: "",
            ruleForm: {
                account: '',
                password: '',
            },
            rules:{
                account:[
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                ],
                password:[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ]
            }
        }
    },
     methods: {
        submitForm(){
            this.codeErrorMsg = '';
            this.$refs['ruleForm'].validate((valid) => {
              if(valid){
                this.btnLoading = true;
                accountLogin(this.ruleForm).then(res=>{
                  if(res.code === 0){
                    setToken(res.data.token);
                    this.$store.commit('LOGIN_ALERT', false);
                    gotoLoginAfterUrlFn()
                  } else {
                    // this.$refs['ruleForm'].clearValidate();
                    this.codeErrorMsg = res.msg;
                  }
                  this.btnLoading = false;
                }).catch(()=>{
                  this.btnLoading = false;
                })
              }
            });

        },
        forgetPass(){
            this.$store.commit('LOGIN_ALERT', false);
            this.$router.push({
                path: '/forget-password'
            })
        }
    },
}
