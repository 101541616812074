
import CodeLogin from "@/components/login/codeLogin";
import AccountLogin from "@/components/login/accountLogin";

export default {
    name: "Login",
    data(){
        return{
            tabActive: 'account'
        }
    },
    methods:{
        /**
         * 注册账号
         */
        registerAccount(){
            this.$store.commit('LOGIN_ALERT', false);
            this.$store.commit('REGISTER_ACCOUNT_ALERT', true);
        },
        loginTypeChange(type){
            this.tabActive = type;
        },
        /**
         * 关闭登录
         * */
        close() {
            this.$store.commit('LOGIN_ALERT', false);
        }
    },
    components:{
        CodeLogin,
        AccountLogin
    }
}
