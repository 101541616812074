
import {mapActions} from "vuex";

export default {
  name: "toolSoftwaresPop",
  props:['list','subpathname'],
  data() {
    return {

    }
  },
  methods:{
    ...mapActions(['setSmproApp']),
    goToPage(link) {
      this.$parent.menuHide = true;
      if (!this.subpathname.includes(link)) {
        this.$router.push({path: link});
      }
      setTimeout(() => {
        this.$parent.menuHide = false;
      }, 100)
    }
  }
}
