
import {companyInfo} from "@/api/company";
import {baseUrl} from '@/api/api-list';
import {STATISTICS_URL} from '@/api/api-list';
import {loginStatusMixin} from '@/mixins/loginStatusMixin';
import {logoutMixin} from '@/mixins/logoutMixin';
import {routerMixin} from '@/mixins/routerMixin';
import {statistics} from "@/api/statistics";
import navListTitle from '@/components/layoutHeader/navListTitle'
import toolSoftwarePop from "@/components/header/toolSoftwarePop.vue";
import sdksPop from "@/components/header/sdksPop.vue";
import resolvePop from "@/components/header/resolvePop.vue";
import de from "element-ui/src/locale/lang/de";

export default {
  data() {
    return {
      baseUrl,
      STATISTICS_URL,
      pathname: '',
      subpathname: '',
      menuHide: false,// 菜单的显示开关
    }
  },
  components: {
    navListTitle,
    toolSoftwarePop,
    sdksPop,
    resolvePop
  },
  mixins: [loginStatusMixin, logoutMixin, routerMixin],
  methods: {
    gotoTestPage(){
      // this.$router.push('/speedtest')
      location.href='/speedtest'
    },

    goHomePage() {
      location.href = '/';
    },
    goPage(item) {
      if (!item.children && item.path !== '/wifi') {
        statistics({
          position: item.statistics,
          type: 'click'
        }).then(res => {
          if (item.target) {
            window.open(item.path);
            return;
          }
          location.href = item.path;
        }).catch(err => {
          if (item.target) {
            window.open(item.path);
            return;
          }
          location.href = item.path;
        })
        // location.href = item.path;
      }
    },
    appUrl() {
      if (process.client) {
        return location.origin;
      }
    },
    goMyapps(url) {
      companyInfo().then(res => {
        if (res.code === 0) {
          if (res.data && res.data !== null) { //已认证
            if (res.data.status == '2' || res.data.status == '4') { // 已经审核通过
              location.href = url;
            } else if (res.data.status == '1' || res.data.status == '3') {
              this.$store.commit('COMPANY_INFO', res.data);
              this.$store.commit('AUTHENTICATION_ALERT', true);
            }
          } else { //未认证
            this.$store.commit('AUTHENTICATION_ALERT', true);
          }
        }
        this.$store.commit('LOADING_STATE', false);
      }).catch(err => {
        this.$store.commit('LOADING_STATE', false);
      })
    },
    goInformation(url) { // 跳转到个人信息
      companyInfo().then(res => {
        if (res.code === 0) {
          if (res.data && res.data !== null) { //已认证
            location.href = url;
          } else { //未认证
            location.href = '/authentication';
          }
        }
        this.$store.commit('LOADING_STATE', false);
      }).catch(err => {
        this.$store.commit('LOADING_STATE', false);
      })
    },
    // 登录
    login() {
      this.$store.commit('LOGIN_ALERT', true);
    },
    /**
     * 修改menu
     * */
    handleMenu() {
      for (let item of this.menuList) {
        if (item.path === location.pathname) {
          this.pathname = location.pathname;
          break;
        }
        if (item.children && item.children.length) {
          for (let subitem of item.children) {
            if (location.pathname&&location.pathname.includes(subitem.path)) {
              this.pathname = subitem.parent;
              this.subpathname = subitem.path;
              break;
            }
          }
          for (let i = 0; i < item.children.length; i++) {
            if (item.children[i].children && item.children[i].children.length) {
              for (let subitem of item.children[i].children) {
                if (location.pathname&&location.pathname.includes(subitem.path)) {
                  this.pathname = item.key;
                  this.subpathname = subitem.path;
                  break;
                }
              }
            }
          }
        }
      }
    },
    goToManagement() {
      location.href='/management'
    }
  },
  updated() {
    if (location.pathname !== this.pathname) {
      this.handleMenu();
    }
  },
  mounted() {
    this.pathname = location.pathname;
    this.handleMenu();
  },
}
