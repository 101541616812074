
import { smsSend, loginPhone } from "@/api/login";
import { setToken } from '@/utils/auth';
import {myTencentCaptcha} from "~/utils/myTencentCaptcha";
import {phoneReg} from "~/config/regConfig";
import {gotoLoginAfterUrlFn} from "~/utils/globalFunction";

export default {
    data() {
        let validatePhone = (rule, value, callback) => {
            let reg = phoneReg;
            if (!value ) {
                callback(new Error('请输入手机号'));
            } else if (!reg.test(value)){
                callback(new Error('请输入正确的手机号'));
            }else {
                callback();
            }
        };
        return {
            btnLoading: false,//登录按钮loading
            phoneErrorMsg: "",//手机号错误信息
            codeErrorMsg: "",//验证码错误信息
            codeBtnText: "发送验证码",//验证码按钮文字说明
            interval: "",//倒计时
            ruleForm: {
                phone: '',
                code: '',
            },
            rules:{
                phone:[
                    { required: true, validator: validatePhone, trigger: 'blur' },
                ],
                code:[
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                ]
            }
        }
    },
     methods: {
        /**
        * 验证码登录
        */
        submitForm(){
          if(!this.btnLoading){
            this.$refs['ruleForm'].validate((valid) => {
              if (valid) {
                this.btnLoading= true;
                this.codeErrorMsg = '';
                loginPhone(this.ruleForm).then(res=>{
                  if(res.code === 0){
                      setToken(res.data.token);
                      this.$store.commit('LOGIN_ALERT', false);
                      if(res.data.loginFirst){//第一次验证码登录，显示绑定注册弹窗
                          this.$store.commit('NOT_REGISTER_ALERT', this.ruleForm.phone);
                      } else {
                        gotoLoginAfterUrlFn()
                      }
                  } else {
                    this.codeErrorMsg = res.msg;
                  }
                  this.btnLoading= false;
                }).catch(()=>{
                  this.btnLoading= false;
                })
              } else {
                  return false;
              }
            });
          }
        },
        /**
         * 获取验证码
         * */
        getCode() {
            if(this.codeBtnText==='发送验证码'||this.codeBtnText==='重新发送'){
                this.$refs.ruleForm.validateField('phone', (check) => { // 判断手机号
                    if(check === ''){
                        let captcha = myTencentCaptcha( res => {
                            if (res.ret === 0) {
                                smsSend({
                                    phone: this.ruleForm.phone,
                                    ticket: res.ticket,
                                    randstr: res.randstr
                                }).then(res => {
                                    this.countdown();
                                }).catch(() => {
                                    this.codeErrText = "获取验证码失败，请重试";
                                })
                            }
                        });
                        captcha.show();
                    } else {
                        this.phoneErrorMsg = check;
                    }
                })
            }
        },
        /**
        * 进行倒计时操作
        * */
        countdown() {
            let initNum = 60;
            this.codeBtnText = `重新发送(${initNum}s)`;
            this.interval = setInterval(() => {
                if (!initNum) {
                    clearInterval(this.interval);
                    this.codeBtnText = "重新发送";
                    return;
                }
                this.codeBtnText = `重新发送(${initNum}s)`;
                initNum--;
            }, 1000)
        },

    },
}
