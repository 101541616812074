import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
  state: () => ({
    loading: false, //页面loading
    authenticationAlert: false, //身份认证弹窗
    reapplyAlert: false, //再次申请弹窗
    loginAlert: false, // 登录弹窗
    registerAccountAlert: false, // 注册账号弹窗
    notRegisterAlert: false, // 第一次登录，进行绑定注册的弹窗
    userInfo: {},//用户信息
    companyInfo: {},//企业信息
    smproApp: 0, // 网维助手拆分app的名称
    showInquiryAlert:false,//注册初次登录
  }),
  mutations: {
    SET_SHOW_INQUIRY_ALERT(state, value) {
      state.showInquiryAlert = value;
    },
    SET_SMPRO_APP(state, value) {
      state.smproApp = value;
    },
    LOADING_STATE(state, value) { //页面loading
      state.loading = value;
    },
    AUTHENTICATION_ALERT(state, value) { //身份认证弹窗
      state.authenticationAlert = value;
    },
    REAPPLY_ALERT(state, value) { //再次申请弹窗
      state.reapplyAlert = value;
    },
    LOGIN_ALERT(state, value) { //登录弹窗
      state.loginAlert = value;
    },
    REGISTER_ACCOUNT_ALERT(state, value) { //注册账号弹窗
      state.registerAccountAlert = value;
    },
    NOT_REGISTER_ALERT(state, value) { //未注册弹窗
      state.notRegisterAlert = value;
    },
    USER_INFO(state, value) { //用户信息
      state.userInfo = value;
    },
    COMPANY_INFO(state, value) { //企业信息
      state.companyInfo = value;
    }
  },
  getters: {
    getterSmproApp: (state) => {
      return state.smproApp;
    }
  },
  actions: {
    /**
     * 修改网维助手拆分app的名称
     * */
    setSmproApp({commit}, val) {
      commit('SET_SMPRO_APP', val);
    },
    setShowInquiryAlert({commit}, val) {
      commit('SET_SHOW_INQUIRY_ALERT', val);
    },
  }
})
export default () => {
  return store;
}

