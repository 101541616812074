import CryptoJS from 'crypto-js';
//上报数据加密参数
export const encryptJson = (json) => {
    let str = JSON.stringify(json);
    let keyStr = '5ECC5D62140EC099';
    let ivStr = 'E63EA892A702EEAA';
    const key = CryptoJS.enc.Utf8.parse(keyStr);  //十六位十六进制数作为密钥
    const iv = CryptoJS.enc.Utf8.parse(ivStr);   //十六位十六进制数作为密钥偏移量
    let srcs = CryptoJS.enc.Utf8.parse(str);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
};
