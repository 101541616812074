
import HeaderCom from "~/components/header.vue";
import FooterCom from "~/components/footer.vue";
import AuthenticationAlert from "~/components/authenticationAlert.vue";
import ReapplyAlert from "~/components/reapplyAlert.vue";
import LoginAlert from "@/components/login/loginAlert";
import RegisterAccountAlert from "@/components/login/registerAccount";
import NotRegisterAlert from "@/components/login/notRegister";
import LoadingModule from "@/components/loading";
import GoBackTop from '@/components/goBackTop';
import {userInfo} from "@/api/myapps";
import { setToken, removeToken } from '@/utils/auth';
import inquiryAlert from '@/components/login/inquiryAlert'

export default {
  components: {
    HeaderCom,
    FooterCom,
    AuthenticationAlert,
    LoginAlert,
    RegisterAccountAlert,
    NotRegisterAlert,
    LoadingModule,
    ReapplyAlert,
    GoBackTop,
    inquiryAlert,
  },
  created(){
    this.$store.commit('LOADING_STATE', true);
  },
  mounted(){
    // 判断是否登录
    userInfo().then(res=>{
        if(res.code === 401){
          removeToken();
          setToken('');
        }
        if(res.code === 0){
          this.$store.commit('USER_INFO', res.data);
        }
        this.$store.commit('LOADING_STATE', false); //全局loading
    }).catch(()=>{
      this.$store.commit('LOADING_STATE', false);//全局loading
    })
  }
}
