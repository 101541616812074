
export default {
  data() {
    return {
      window: null,
    };
  },
  mounted(){
    this.window = window
  },
  methods: {
    goToLink (url) {
      //this.$router.push(url)
      window.open(this.window.location.origin + url)
    }
  }
}
