export const interfaceEncryptionLists =
  [
    '/user/info',
    '/company/info',
    '/company/infoApproved',
    '/company/store',
    '/consult/info',
    '/SpeedData/index',
    '/SpeedData/detail'
  ]
