
export default {
  data(){
    return{
        scrollTop: 0,// 滚动条的高度
    }
  },
  mounted(){
      window.addEventListener('scroll', this.handleScroll, true);
  },
  beforeDestroyed () {
     window.removeEventListener('scroll', this.handleScroll);// 离开该页面需要移除监听滚动条的事件，不然会报错
  },
  methods:{
    handleScroll () {// 保存滚动值，这是兼容的写法
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    },
    goTop() {
        let timer = setInterval(function () {
            let osTop = document.documentElement.scrollTop || document.body.scrollTop;
            let ispeed = Math.floor(-osTop / 5);
            document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
            if (osTop === 0) {
                clearInterval(timer);
            }
        }, 30);
    }
  },
}
