// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/imgs/login/tip.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".code-login-index .item-code[data-v-322bd435] {\n  position: relative;\n}\n.code-login-index .item-code .item-code-input[data-v-322bd435] {\n  width: 120px;\n  position: absolute;\n  left: 215px;\n  bottom: 4px;\n  display: flex;\n  align-items: center;\n}\n.code-login-index .item-code .item-code-input .left-line[data-v-322bd435] {\n  width: 1px;\n  height: 20px;\n  background: #E6E6E7;\n}\n.code-login-index .item-code .item-code-input .send-code[data-v-322bd435] {\n  font-size: 16px;\n  font-weight: 400;\n  color: #BBBBBB;\n  margin: 0 auto;\n}\n.code-login-index .item-code .item-code-input .again-send[data-v-322bd435] {\n  color: #666666;\n  cursor: pointer;\n}\n.code-login-index .item-code .code-tip[data-v-322bd435] {\n  position: absolute;\n  right: 0;\n  bottom: -24px;\n}\n.code-login-index .item-code .code-tip:hover .code-tip-pop[data-v-322bd435] {\n  display: block;\n}\n.code-login-index .item-code .code-tip p[data-v-322bd435] {\n  font-size: 14px;\n  font-weight: 400;\n  color: #067CFA;\n  line-height: 20px;\n  cursor: pointer;\n}\n.code-login-index .item-code .code-tip .code-tip-pop[data-v-322bd435] {\n  width: 268px;\n  height: 124px;\n  display: none;\n  position: absolute;\n  right: -60px;\n  bottom: 22px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 268px 124px;\n  padding: 13px 0 0 23px;\n}\n.code-login-index .item-code .code-tip .code-tip-pop p[data-v-322bd435] {\n  font-size: 14px;\n  font-weight: 400;\n  color: #FFFFFF;\n  line-height: 20px;\n}\n.code-login-index .item-code .code-tip .code-tip-pop p[data-v-322bd435]:first-child {\n  margin-bottom: 8px;\n}\n", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
