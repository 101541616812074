import { render, staticRenderFns } from "./authenticationAlert.vue?vue&type=template&id=1f9ac04a&scoped=true&"
import script from "./authenticationAlert.vue?vue&type=script&lang=js&"
export * from "./authenticationAlert.vue?vue&type=script&lang=js&"
import style0 from "./authenticationAlert.vue?vue&type=style&index=0&id=1f9ac04a&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f9ac04a",
  null
  
)

export default component.exports